import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  ArrowRight, 
  Users, 
  TrendingUp, 
  Star, 
  MessageSquare,
  Calendar,
  BarChart2
} from 'lucide-react';
import api from '../../utils/api';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const SurveyResults = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSurveyAndResponses();
  }, [surveyId]);

  const fetchSurveyAndResponses = async () => {
    try {
      const [surveyRes, responsesRes] = await Promise.all([
        api.get(`/api/surveys/${surveyId}`),
        api.get(`/api/surveys/${surveyId}/responses`)
      ]);
      setSurvey(surveyRes.data);
      setResponses(responsesRes.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching survey data:', error);
      setError('שגיאה בטעינת תוצאות הסקר');
      setLoading(false);
    }
  };

  const calculateStats = (questionIndex, type) => {
    const answers = responses.map(r => r.answers[questionIndex]?.answer).filter(Boolean);
    
    if (type === 'number') {
      const numericAnswers = answers.map(Number);
      return {
        average: (numericAnswers.reduce((a, b) => a + b, 0) / numericAnswers.length).toFixed(2),
        min: Math.min(...numericAnswers),
        max: Math.max(...numericAnswers),
        count: numericAnswers.length,
        distribution: calculateDistribution(numericAnswers)
      };
    }
    
    return {
      count: answers.length,
      answers: answers
    };
  };

  const calculateDistribution = (numbers) => {
    const distribution = {};
    numbers.forEach(num => {
      distribution[num] = (distribution[num] || 0) + 1;
    });
    return distribution;
  };

  const getOverallStats = () => {
    if (!responses.length) return null;

    const totalResponses = responses.length;
    
    // Calculate average satisfaction rating (Question 1)
    const avgSatisfaction = responses.reduce((acc, response) => {
      const rating = Number(response.answers[0]?.answer || 0);
      return acc + rating;
    }, 0) / totalResponses;

    // Enhanced visits calculation (Question 2)
    const visitResponses = responses
      .map(response => Number(response.answers[1]?.answer || 0))
      .filter(visits => visits > 0); // Filter out zero or invalid responses

    const visitStats = {
      highest: Math.max(...visitResponses),
      lowest: Math.min(...visitResponses),
      average: (visitResponses.reduce((a, b) => a + b, 0) / visitResponses.length).toFixed(1),
      // Track monthly data for trend analysis
      monthlyData: responses.reduce((acc, response) => {
        const visits = Number(response.answers[1]?.answer || 0);
        const responseDate = new Date(response.createdAt);
        const monthKey = `${responseDate.getFullYear()}-${responseDate.getMonth() + 1}`;
        
        if (!acc[monthKey]) {
          acc[monthKey] = [];
        }
        
        acc[monthKey].push(visits);
        return acc;
      }, {})
    };

    // Calculate trend based on monthly averages
    const monthlyAverages = Object.entries(visitStats.monthlyData)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([_, visits]) => 
        visits.reduce((sum, v) => sum + v, 0) / visits.length
      );

    visitStats.trend = monthlyAverages.length > 1 
      ? monthlyAverages[monthlyAverages.length - 1] > monthlyAverages[0] ? 'up' : 'down'
      : 'stable';

    // Calculate average professionalism rating (Question 3)
    const avgProfessionalism = responses.reduce((acc, response) => {
      const rating = Number(response.answers[2]?.answer || 0);
      return acc + rating;
    }, 0) / totalResponses;

    return {
      totalResponses,
      avgSatisfaction: avgSatisfaction.toFixed(1),
      avgVisits: visitStats.average,
      visitStats,
      avgProfessionalism: avgProfessionalism.toFixed(1)
    };
  };

  const renderOverallStats = () => {
    const stats = getOverallStats();
    if (!stats) return null;

    const statCards = [
      { 
        title: 'סה"כ משובים',
        value: stats.totalResponses,
        icon: Users,
        color: 'blue',
        description: 'סך כל המשובים שהתקבלו'
      },
      { 
        title: 'שביעות רצון',
        value: `${stats.avgSatisfaction}/5`,
        icon: Star,
        color: 'yellow',
        description: 'ציון ממוצע של שביעות רצון'
      },
      { 
        title: 'ביקורים בחודש',
        value: stats.avgVisits,
        icon: Calendar,
        color: 'green',
        description: `טווח ביקורים חודשי ${stats.visitStats.trend === 'up' ? '📈' : stats.visitStats.trend === 'down' ? '📉' : '➡️'}`,
        subStats: [
          { 
            label: 'מקסימום', 
            value: stats.visitStats.highest,
            tooltip: 'מספר הביקורים המקסימלי שדווח'
          },
          { 
            label: 'מינימום', 
            value: stats.visitStats.lowest,
            tooltip: 'מספר הביקורים המינימלי שדווח'
          }
        ]
      },
      { 
        title: 'רמת מקצועיות',
        value: `${stats.avgProfessionalism}/5`,
        icon: TrendingUp,
        color: 'purple',
        description: 'ציון ממוצע של רמת מקצועיות'
      }
    ];

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {statCards.map((stat, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className={`bg-white rounded-xl shadow-lg p-6 border-t-4 border-${stat.color}-500`}
          >
            <div className="flex items-center justify-between mb-2">
              <div>
                <p className="text-sm text-gray-600">{stat.title}</p>
                <p className="text-2xl font-bold text-gray-800">{stat.value}</p>
                {stat.subStats && (
                  <div className="mt-2 grid grid-cols-2 gap-2">
                    {stat.subStats.map((subStat, i) => (
                      <div key={i} className="text-xs">
                        <span className="text-gray-500">{subStat.label}: </span>
                        <span className="font-medium">{subStat.value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <stat.icon className={`h-8 w-8 text-${stat.color}-500`} />
            </div>
            <p className="text-xs text-gray-500">{stat.description}</p>
          </motion.div>
        ))}
      </div>
    );
  };

  const renderQuestionResults = (question, index, stats) => {
    return (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        className="bg-white rounded-xl shadow-lg p-6"
      >
        <div className="flex items-center mb-4">
          {question.type === 'number' ? (
            <BarChart2 className="h-6 w-6 text-blue-500 ml-2" />
          ) : (
            <MessageSquare className="h-6 w-6 text-green-500 ml-2" />
          )}
          <h3 className="text-lg font-semibold text-gray-800">{question.text}</h3>
        </div>

        {question.type === 'number' ? (
          <div className="space-y-6">
            {/* Stats Grid */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {[
                { label: 'ממוצע', value: stats.average },
                { label: 'מינימום', value: stats.min },
                { label: 'מקסימום', value: stats.max },
                { label: 'מספר תשובות', value: stats.count }
              ].map((stat, i) => (
                <div key={i} className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">{stat.label}</p>
                  <p className="text-xl font-bold text-gray-800">{stat.value}</p>
                </div>
              ))}
            </div>

            {/* Charts Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Line Chart */}
              <div className="bg-white p-4 rounded-lg shadow">
                <Line 
                  data={{
                    labels: Object.keys(stats.distribution),
                    datasets: [{
                      label: 'התפלגות תשובות',
                      data: Object.values(stats.distribution),
                      borderColor: '#4C51BF',
                      backgroundColor: 'rgba(76, 81, 191, 0.1)',
                      tension: 0.4
                    }]
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'top' },
                      title: { display: true, text: 'מגמת תשובות' }
                    }
                  }}
                />
              </div>

              {/* Doughnut Chart */}
              <div className="bg-white p-4 rounded-lg shadow">
                <Doughnut
                  data={{
                    labels: Object.keys(stats.distribution),
                    datasets: [{
                      data: Object.values(stats.distribution),
                      backgroundColor: [
                        'rgba(76, 81, 191, 0.8)',
                        'rgba(104, 211, 145, 0.8)',
                        'rgba(234, 179, 8, 0.8)',
                        'rgba(239, 68, 68, 0.8)',
                        'rgba(168, 85, 247, 0.8)'
                      ]
                    }]
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: 'top' },
                      title: { display: true, text: 'חלוקת תשובות' }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-3 max-h-96 overflow-y-auto">
            {stats.answers.map((answer, i) => (
              <motion.div 
                key={i}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: i * 0.05 }}
                className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors"
              >
                <p className="text-gray-800">{answer}</p>
              </motion.div>
            ))}
          </div>
        )}
      </motion.div>
    );
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    </div>
  );

  if (error) return (
    <div className="text-center p-8 text-red-600 bg-red-50 rounded-lg">
      <p>{error}</p>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <button 
          onClick={() => navigate('/dashboard/admin/surveys')}
          className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
        >
          <ArrowRight className="h-5 w-5 ml-2" />
          חזרה לרשימת הסקרים
        </button>
      </div>

      {/* Survey Title and Description */}
      <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">{survey?.title}</h1>
        <p className="text-gray-600">{survey?.description}</p>
      </div>

      {/* Overall Stats Cards */}
      {renderOverallStats()}

      {/* Questions Results */}
      <div className="space-y-8">
        {survey?.questions.map((question, index) => 
          renderQuestionResults(question, index, calculateStats(index, question.type))
        )}
      </div>
    </div>
  );
};

export default SurveyResults; 