import React from 'react';
import { Plus, Trash2, Building2, Search } from 'lucide-react';
import { useBranchManagement } from './hooks';

const BranchManagement = () => {
  const { 
    newBranch,
    branches,
    handleNewBranchChange,
    addNewBranch, 
    deleteBranch,
    searchTerm,
    setSearchTerm,
    error, 
    success,
    isLoading 
  } = useBranchManagement();

  const filteredBranches = branches.filter(branch => 
    branch.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      {/* Add Branch Section */}
      <div className="bg-white p-6 rounded-xl shadow-lg">
        <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
          <Building2 className="h-6 w-6 ml-2 text-blue-500" />
          הוסף סניף
        </h3>
        
        {error && (
          <div className="bg-red-50 text-red-700 p-3 rounded-lg mb-4 flex items-center">
            <span className="ml-2">⚠️</span>
            {error}
          </div>
        )}
        
        {success && (
          <div className="bg-green-50 text-green-700 p-3 rounded-lg mb-4 flex items-center">
            <span className="ml-2">✅</span>
            {success}
          </div>
        )}

        <div className="flex flex-col space-y-4">
          {/* Branch Name Input */}
          <div className="flex items-center space-x-2">
            <Building2 className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              id="newBranch"
              value={newBranch}
              onChange={handleNewBranchChange}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              placeholder="שם הסניף"
            />
          </div>

          {/* Add Button */}
          <button 
            onClick={addNewBranch}
            disabled={isLoading || !newBranch.trim()}
            className="w-full sm:w-auto bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-3 rounded-lg
                     hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 
                     focus:ring-offset-2 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed
                     transform hover:-translate-y-0.5"
          >
            <div className="flex items-center justify-center">
              <Plus className="h-5 w-5 ml-2" />
              הוסף סניף
            </div>
          </button>
        </div>
      </div>

      {/* Branches List Section */}
      <div className="bg-white p-6 rounded-xl shadow-lg">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-800">רשימת סניפים</h3>
          <div className="relative">
            <input
              type="text"
              placeholder="חיפוש סניף..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="h-5 w-5 text-gray-400 absolute right-3 top-2.5" />
          </div>
        </div>

        <div className="divide-y divide-gray-100">
          {filteredBranches.length > 0 ? (
            filteredBranches.map((branch) => (
              <div 
                key={branch._id} 
                className="flex items-center justify-between py-4 group hover:bg-gray-50 rounded-lg px-3 transition-colors duration-200"
              >
                <div className="flex items-center">
                  <Building2 className="h-5 w-5 text-gray-400 ml-3" />
                  <span className="text-gray-700 font-medium">{branch.name}</span>
                </div>
                <button
                  onClick={() => deleteBranch(branch._id)}
                  className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50 
                           opacity-0 group-hover:opacity-100 transition-all duration-200"
                  title="מחק סניף"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              {searchTerm ? 'לא נמצאו סניפים התואמים את החיפוש' : 'אין סניפים להצגה'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BranchManagement;