import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Scatter } from 'react-chartjs-2';
import { Loader, AlertCircle, Brain, TrendingUp, Lightbulb, Target, Users, Wrench, Star, ChartBar } from 'lucide-react';
import { API_URL } from '../config';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const AIAnalyzerContent = () => {
  const [analysisData, setAnalysisData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const MINIMUM_REPORTS = 10;

  useEffect(() => {
    const fetchAnalysisData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/reports/ai-analysis`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        
        // Always check totalReports, even if there's an error
        const totalReports = response.data.totalReports || 0;
        
        if (totalReports < MINIMUM_REPORTS) {
          setError({
            type: 'insufficient_data',
            message: `נדרשים לפחות ${MINIMUM_REPORTS} דוחות חודשיים לניתוח AI מדויק. כרגע יש ${totalReports} דוחות בלבד.`,
            suggestion: 'נא להמשיך למלא דוחות יומיים. כשיהיו מספיק דוחות, המערכת תוכל לספק תובנות מעמיקות על ביצועי המחסן.',
            currentReports: totalReports
          });
          return;
        }

        if (response.data.analysis) {
          setAnalysisData(response.data.analysis);
        } else {
          setError({
            type: 'insufficient_data',
            message: `נדרשים לפחות ${MINIMUM_REPORTS} דוחות חודשיים לניתוח AI מדויק.`,
            suggestion: 'נא להמשיך למלא דוחות יומיים. כשיהיו מספיק דוחות, המערכת תוכל לספק תובנות מעמיקות על ביצועי המחסן.',
            currentReports: 0
          });
        }
      } catch (error) {
        console.error('שגיאה בטעינת נתוני ניתוח AI:', error);
        
        // Get total reports from error response if available
        const totalReports = error.response?.data?.totalReports || 0;
        
        setError({
          type: 'insufficient_data',
          message: `נדרשים לפחות ${MINIMUM_REPORTS} דוחות חודשיים לניתוח AI מדויק. כרגע יש ${totalReports} דוחות בלבד.`,
          suggestion: 'נא להמשיך למלא דוחות יומיים. כשיהיו מספיק דוחות, המערכת תוכל לספק תובנות מעמיקות על ביצועי המחסן.',
          currentReports: totalReports
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysisData();
  }, []);

  const COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6'];

  const InsightCard = ({ title, insights, icon: Icon, color }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200">
      <div className="flex items-center mb-4">
        <div className={`p-3 rounded-full bg-${color}-100 ml-4`}>
          <Icon className={`h-6 w-6 text-${color}-600`} />
        </div>
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
      </div>
      <ul className="space-y-2">
        {insights && insights.map((insight, index) => (
          <li key={index} className="flex items-start text-gray-600">
            <Lightbulb className="h-5 w-5 text-yellow-500 ml-2 mt-1 flex-shrink-0" />
            <span>{insight}</span>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderBarChart = (data) => {
    if (!data || data.length === 0) return null;
    
    const chartData = {
      labels: data.map(d => d.name),
      datasets: [
        {
          label: 'ציון ממוצע',
          data: data.map(d => d.value),
          backgroundColor: COLORS,
          borderColor: COLORS,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'ציוני מחסנים',
          font: {
            size: 16,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 10,
          ticks: {
            stepSize: 1,
          },
        },
      },
    };

    return (
      <div className="h-64 md:h-80 w-full">
        <Bar data={chartData} options={options} />
      </div>
    );
  };

  const generateDataDrivenConclusions = (data) => {
    if (!data) return [];

    const conclusions = [];

    // Analyze correlation between returns corner score and abnormal goods
    const returnsCornerScore = data.warehouseScores.averageScores.returnsCorner;
    const abnormalGoodsPercentage = parseFloat(data.abnormalGoods.percentage);
    if (returnsCornerScore < 7 && abnormalGoodsPercentage > 10) {
      conclusions.push({
        title: "קשר בין פינת החזרות לסחורה חריגה",
        description: `נמצא קשר אפשרי בין ציון נמוך בפינת החזרות (${returnsCornerScore.toFixed(1)}) לאחוז גבוה של סחורה חריגה (${abnormalGoodsPercentage}%). שיפור בניהול פינת החזרות עשוי להפחית את כמות הסחורה החריגה.`,
        actionItem: "לשקול הדרכה מחודשת בנושא ניהול פינת החזרות ולבחון את תהליכי הטיפול בסחורה חריגה."
      });
    }

    // Analyze workforce efficiency
    const excessManpowerPercentage = (data.workforceAnalysis.excessManpower / data.workforceAnalysis.totalReports) * 100;
    const lackOfManpowerPercentage = (data.workforceAnalysis.lackOfManpower / data.workforceAnalysis.totalReports) * 100;
    if (excessManpowerPercentage > 20 && lackOfManpowerPercentage > 20) {
      conclusions.push({
        title: "חוסר איזון בניהול כוח האדם",
        description: `נמצא חוסר איזון משמעותי בניהול כוח האדם: ${excessManpowerPercentage.toFixed(1)}% מהדוחות מציינים עודף כוח אדם, בעוד ${lackOfManpowerPercentage.toFixed(1)}% מציינים חוסר. זה מצביע על בעיה בתכנון ובהקצאת משאבי אנוש.`,
        actionItem: "לבצע ניתוח מעמיק של דפוסי העבודה ולשקול יישום מערכת תכנון משמרות גמישה יותר."
      });
    }

    // Analyze equipment status and its impact
    const defectiveEquipmentPercentage = (data.equipmentAnalysis.defectiveEquipment / data.equipmentAnalysis.totalReports) * 100;
    if (defectiveEquipmentPercentage > 15) {
      conclusions.push({
        title: "השפעת ציוד לא תקין על ביצועים",
        description: `${defectiveEquipmentPercentage.toFixed(1)}% מהדוחות מציינים ציוד לא תקין. זה עלול להשפיע לרעה על יעילות העבודה ועל איכות הטיפול בסחורה.`,
        actionItem: "להגדיר תוכנית תחזוקה מונעת ולשקול השקעה בחידוש ציוד ישן או לא תקין."
      });
    }

    // Analyze branch performance
    const branchPerformance = data.branchAnalysis.branchScores;
    const lowPerformingBranches = Object.entries(branchPerformance)
      .filter(([_, score]) => score < 6.5)
      .map(([branch, _]) => branch);
    if (lowPerformingBranches.length > 0) {
      conclusions.push({
        title: "סניפים הדורשים תשומת לב מיוחדת",
        description: `זוהו ${lowPerformingBranches.length} סניפים עם ביצועים נמוכים (ציון ממוצע מתחת ל-6.5): ${lowPerformingBranches.join(', ')}. יש לבחון את הגורמים לביצועים הנמוכים בסניפים אלה.`,
        actionItem: "לערוך ביקורת מעמיקה בסניפים אלה ולפתח תוכנית שיפור ייעודית לכל סניף."
      });
    }

    return conclusions;
  };

  const DataDrivenConclusions = () => (
    <div className="bg-white p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
      <h2 className="text-2xl font-semibold mb-6 flex items-center text-gray-800">
        <ChartBar className="h-6 w-6 text-blue-500 ml-2" />
        מסקנות מבוססות נתונים
      </h2>
      <div className="space-y-6">
        {generateDataDrivenConclusions(analysisData).map((conclusion, index) => (
          <div key={index} className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-xl font-semibold text-blue-600 mb-2">{conclusion.title}</h3>
            <p className="text-gray-700 mb-3">{conclusion.description}</p>
            <p className="text-green-600 font-semibold flex items-center">
              <Target className="h-5 w-5 ml-2" />
              פעולה מומלצת: {conclusion.actionItem}
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderScatterPlot = () => {
    const data = analysisData.branchAnalysis.branchScores;
    const scatterData = Object.entries(data).map(([branch, score]) => ({
      x: score,
      y: analysisData.abnormalGoods.branchData[branch] || 0,
      branch
    }));

    const chartData = {
      datasets: [
        {
          label: 'סניפים',
          data: scatterData,
          backgroundColor: COLORS[0],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'יחס בין ציון הסניף לכמות סחורה חריגה',
          font: {
            size: 16,
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => `סניף: ${context.raw.branch}, ציון: ${context.parsed.x.toFixed(2)}, סחורה חריגה: ${context.parsed.y}`,
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'ציון סניף',
          },
          min: 0,
          max: 10,
          ticks: {
            stepSize: 1,
          },
        },
        y: {
          title: {
            display: true,
            text: 'כמות סחורה חריגה',
          },
          beginAtZero: true,
        },
      },
    };

    return (
      <div className="h-64 md:h-80 w-full">
        <Scatter data={chartData} options={options} />
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className={`rounded-lg p-6 ${error.type === 'insufficient_data' ? 'bg-blue-50 border border-blue-200' : 'bg-red-50 border border-red-200'}`}>
          <div className="flex flex-col items-center text-center space-y-4">
            {error.type === 'insufficient_data' ? (
              <Brain className="h-16 w-16 text-blue-500" />
            ) : (
              <AlertCircle className="h-16 w-16 text-red-500" />
            )}
            <h2 className="text-2xl font-bold text-gray-800">{error.type === 'insufficient_data' ? 'אין מספיק נתונים לניתוח' : 'שגיאה'}</h2>
            <p className="text-lg text-gray-600">{error.message}</p>
            {error.suggestion && (
              <div className="mt-4 p-4 bg-white rounded-lg shadow-sm border border-blue-100">
                <p className="text-blue-600">
                  <Lightbulb className="inline-block h-5 w-5 ml-2" />
                  {error.suggestion}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (!analysisData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-yellow-100 border-r-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>אין נתוני ניתוח זמינים.</p>
          </div>
        </div>
      </div>
    );
  }

  const { damagedGoods, abnormalGoods, warehouseScores, generalInsights, branchAnalysis, workforceAnalysis, equipmentAnalysis, improvementSuggestions } = analysisData;

  if (error?.type === 'insufficient_data') {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-2xl mx-auto">
          <div className="flex flex-col items-center text-center space-y-6">
            <div className="bg-blue-50 p-4 rounded-full">
              <Brain className="h-16 w-16 text-blue-500" />
            </div>
            
            <h2 className="text-2xl font-bold text-gray-800">ממתין לנתונים נוספים</h2>
            
            <div className="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700">
              <div 
                className="bg-blue-500 h-4 rounded-full transition-all duration-500" 
                style={{ width: `${Math.min((error.currentReports / MINIMUM_REPORTS) * 100, 100)}%` }}
              ></div>
            </div>
            
            <p className="text-lg text-gray-600">
              {error.message}
            </p>
            
            <div className="bg-blue-50 p-4 rounded-lg w-full">
              <p className="text-blue-700 flex items-center">
                <Lightbulb className="inline-block h-5 w-5 ml-2 flex-shrink-0" />
                <span>{error.suggestion}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50" dir="rtl">
      <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">ניתוח AI מתקדם</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <InsightCard 
          title={damagedGoods?.title || "מוצרים פגומים חריגים"}
          insights={damagedGoods?.insights}
          icon={Brain} 
          color="red"
        />
        <InsightCard 
          title={abnormalGoods?.title || "מוצרים תקינים חריגים"}
          insights={abnormalGoods?.insights}
          icon={TrendingUp} 
          color="blue"
        />
      </div>

      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">{warehouseScores?.title || "ציוני מחסנים"}</h2>
        {renderBarChart(warehouseScores?.averageScores ? Object.entries(warehouseScores.averageScores).map(([key, value]) => ({ name: getHebrewName(key), value })) : [])}
        <ul className="mt-6 space-y-2">
          {warehouseScores?.insights?.map((insight, index) => (
            <li key={index} className="flex items-start">
              <Lightbulb className="h-5 w-5 text-yellow-500 ml-2 mt-1 flex-shrink-0" />
              <span className="text-gray-700">{insight}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <InsightCard 
          title={branchAnalysis?.title || "ניתוח סניפים"}
          insights={branchAnalysis?.insights}
          icon={Target} 
          color="green"
        />
        <InsightCard 
          title={workforceAnalysis?.title || "ניתוח כוח אדם"}
          insights={workforceAnalysis?.insights}
          icon={Users} 
          color="indigo"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <InsightCard 
          title={equipmentAnalysis?.title || "ניתוח ציוד"}
          insights={equipmentAnalysis?.insights}
          icon={Wrench} 
          color="purple"
        />
        <InsightCard 
          title={improvementSuggestions?.title || "הצעות לשיפור"}
          insights={improvementSuggestions?.insights}
          icon={Star} 
          color="yellow"
        />
      </div>

      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">{generalInsights?.title || "תובנות כלליות"}</h2>
        <ul className="space-y-3">
          {generalInsights?.insights?.map((insight, index) => (
            <li key={index} className="flex items-start bg-blue-50 p-3 rounded-lg">
              <Target className="h-5 w-5 text-blue-500 ml-2 mt-1 flex-shrink-0" />
              <span className="text-gray-800">{insight}</span>
            </li>
          ))}
        </ul>
      </div>

      <DataDrivenConclusions />

      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg mb-8 border border-gray-200">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">יחס בין ציון הסניף לכמות סחורה חריגה</h2>
        {renderScatterPlot()}
      </div>
    </div>
  );
};

// Helper function to get Hebrew names for chart labels
const getHebrewName = (key) => {
  const hebrewNames = {
    storageArrangement: "סידור מחסן",
    checkLevel: "רמת בדיקה",
    warehousemanStation: "עמדת מחסנאי",
    returnsCorner: "פינת חזרות"
  };
  return hebrewNames[key] || key;
};

export default AIAnalyzerContent;
