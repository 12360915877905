import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Trash2, AlertCircle, Search, Image, X, ChevronLeft, ChevronRight, MapPin } from 'lucide-react';
import { useSelector } from 'react-redux';

const ReportsManagement = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const user = useSelector(state => state.auth.user);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/reports/all`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setReports(response.data);
    } catch (error) {
      setError('Failed to fetch reports');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (reportId) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק דוח זה?')) {
      try {
        await axios.delete(`${API_URL}/api/reports/${reportId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setReports(reports.filter(report => report._id !== reportId));
      } catch (error) {
        setError('Failed to delete report');
        console.error('Error:', error);
      }
    }
  };

  const filteredReports = reports.filter(report => 
    report.branch.toLowerCase().includes(searchTerm.toLowerCase()) ||
    report.userId?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    new Date(report.date).toLocaleDateString().includes(searchTerm)
  );

  const handleShowGallery = (report) => {
    setSelectedReport(report);
    setShowGallery(true);
    setCurrentImageIndex(0);
  };

  const handleCloseGallery = () => {
    setShowGallery(false);
    setSelectedReport(null);
    setCurrentImageIndex(0);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? selectedReport.photos.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === selectedReport.photos.length - 1 ? 0 : prev + 1
    );
  };

  const PhotoGalleryModal = () => {
    if (!showGallery || !selectedReport) return null;

    const photos = selectedReport.photos || [];
    const currentPhoto = photos[currentImageIndex];

    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center">
        <div className="relative w-full max-w-4xl mx-4 bg-white rounded-lg shadow-xl">
          {/* Modal Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <h3 className="text-xl font-semibold">
              תמונות מדוח - {selectedReport.branch} ({new Date(selectedReport.date).toLocaleDateString()})
              {selectedReport.location && (
                <a
                  href={getGoogleMapsUrl(selectedReport.location)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 hover:text-blue-800 mr-2 inline-flex items-center"
                >
                  <MapPin size={16} className="ml-1" />
                  צפה במיקום
                </a>
              )}
            </h3>
            <button
              onClick={handleCloseGallery}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <X size={24} />
            </button>
          </div>

          {/* Gallery Content */}
          <div className="relative">
            {photos.length > 0 ? (
              <div className="relative h-[60vh]">
                <img
                  src={currentPhoto.url}
                  alt={currentPhoto.caption || `תמונה ${currentImageIndex + 1}`}
                  className="w-full h-full object-contain"
                />
                {currentPhoto.caption && (
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-center">
                    {currentPhoto.caption}
                  </div>
                )}
                
                {/* Navigation Buttons */}
                {photos.length > 1 && (
                  <>
                    <button
                      onClick={handlePrevImage}
                      className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
                    >
                      <ChevronLeft size={24} />
                    </button>
                    <button
                      onClick={handleNextImage}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
                    >
                      <ChevronRight size={24} />
                    </button>
                  </>
                )}
              </div>
            ) : (
              <div className="h-48 flex items-center justify-center text-gray-500">
                אין תמונות בדוח זה
              </div>
            )}
          </div>

          {/* Thumbnails */}
          {photos.length > 1 && (
            <div className="p-4 border-t overflow-x-auto">
              <div className="flex space-x-2 space-x-reverse">
                {photos.map((photo, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImageIndex(index)}
                    className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden border-2 
                      ${currentImageIndex === index ? 'border-blue-500' : 'border-transparent'}`}
                  >
                    <img
                      src={photo.url}
                      alt={photo.caption || `תמונה ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getGoogleMapsUrl = (location) => {
    if (!location || !location.latitude || !location.longitude) return null;
    return `https://www.google.com/maps?q=${location.latitude},${location.longitude}`;
  };

  if (!user || user.role !== 'admin') {
    return <div className="p-4">Not authorized</div>;
  }

  return (
    <div className="container mx-auto p-4" dir="rtl">
      <h1 className="text-2xl font-bold mb-4">ניהול דוחות</h1>
      
      {/* Search Bar */}
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="חיפוש לפי סניף, שם עובד או תאריך..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 pr-10 border rounded-lg"
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
      </div>

      {error && (
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4 mb-4">
          <div className="flex items-center">
            <AlertCircle className="ml-2" />
            <span>{error}</span>
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center">טוען...</div>
      ) : (
        <div className="flex justify-center w-full">
          <div className="overflow-x-auto max-w-7xl w-full">
            <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-lg">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-center">תאריך</th>
                  <th className="px-4 py-2 text-center">סניף</th>
                  <th className="px-4 py-2 text-center">שם המנהל</th>
                  <th className="px-4 py-2 text-center">ציונים</th>
                  <th className="px-4 py-2 text-center">מיקום</th>
                  <th className="px-4 py-2 text-center">פעולות</th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.map(report => (
                  <tr key={report._id} className="border-b hover:bg-gray-50">
                    <td className="px-4 py-2 text-center">
                      {new Date(report.date).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2 text-center">{report.branch}</td>
                    <td className="px-4 py-2 text-center">{report.userId?.fullName}</td>
                    <td className="px-4 py-2 text-center">
                      <div className="text-sm">
                        <div className="text-center">סידור מחסן: {report.storageArrangementScore}</div>
                        <div className="text-center">רמת בדיקה: {report.checkLevelScore}</div>
                        <div className="text-center">עמדת מחסנאי: {report.warehousemanStationScore}</div>
                        <div className="text-center">פינת חזרות: {report.returnsCornerScore}</div>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center">
                      {report.location ? (
                        <a
                          href={getGoogleMapsUrl(report.location)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800 inline-flex items-center"
                        >
                          <MapPin size={20} className="ml-1" />
                          צפה במפה
                        </a>
                      ) : (
                        <span className="text-gray-400">אין מיקום</span>
                      )}
                    </td>
                    <td className="px-4 py-2 text-center">
                      <div className="flex justify-center space-x-2 space-x-reverse">
                        <button
                          onClick={() => handleDelete(report._id)}
                          className="p-2 text-red-600 hover:bg-red-100 rounded-full transition-colors"
                          title="מחק דוח"
                        >
                          <Trash2 size={20} />
                        </button>
                        {report.photos?.length > 0 && (
                          <button
                            onClick={() => handleShowGallery(report)}
                            className="p-2 text-blue-600 hover:bg-blue-100 rounded-full transition-colors"
                            title="הצג תמונות"
                          >
                            <Image size={20} />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <PhotoGalleryModal />
    </div>
  );
};

export default ReportsManagement; 