import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Loader, AlertCircle, CheckCircle, Clock, Plus, X, Info, Camera, Image as ImageIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

const Tasks = () => {
  const user = useSelector(state => state.auth.user);
  const [tasks, setTasks] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTask, setNewTask] = useState({ 
    title: '', 
    description: '', 
    dueDate: '', 
    branch: '',
    status: 'פתוח',
    photos: []
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      fetchTasks();
      fetchBranches();
    }
  }, [user]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/tasks`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to load tasks. Please try again later.');
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      setError('Failed to load branches. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'dueDate') {
        setNewTask({ ...newTask, [name]: value });
    } else {
        setNewTask({ ...newTask, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError('');
    setIsLoading(true);

    try {
      const taskToSubmit = {
        ...newTask,
        assignedTo: user.userId
      };

      const response = await axios.post(`${API_URL}/api/tasks`, taskToSubmit, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      setTasks([...tasks, response.data]);
      setIsPopupOpen(false);
      setNewTask({ 
        title: '', 
        description: '', 
        dueDate: '', 
        branch: '',
        status: 'פתוח',
        photos: []
      });
    } catch (error) {
      console.error('Error creating task:', error);
      setFormError(error.response?.data?.message || 'אירעה שגיאה ביצירת המשימה. אנא נסה שנית.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      const response = await axios.put(`${API_URL}/api/tasks/${taskId}`, { status: newStatus }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setTasks(tasks.map(task => task._id === taskId ? response.data : task));
    } catch (error) {
      console.error('Error updating task status:', error);
      setError('Failed to update task status. Please try again.');
    }
  };

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs sm:text-sm font-medium text-gray-600">{title}</p>
          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-2 sm:p-3 rounded-full bg-${color}-100`}>
          <Icon className={`h-4 w-4 sm:h-6 sm:w-6 text-${color}-600`} />
        </div>
      </div>
    </div>
  );

  const renderBranchSelect = () => (
    <div className="mb-4">
      <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
      <select
        id="branch"
        name="branch"
        value={newTask.branch}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 rounded-md text-sm"
        required
      >
        <option value="">בחר סניף</option>
        {branches.map(branch => (
          <option key={branch._id} value={branch.name}>
            {branch.name}
          </option>
        ))}
      </select>
    </div>
  );

  const handlePhotoUpload = async (e) => {
    const files = Array.from(e.target.files);
    
    for (const file of files) {
      try {
        if (!file.type.startsWith('image/')) {
          setFormError('נא להעלות קבצי תמונה בלבד');
          continue;
        }

        if (file.size > 10 * 1024 * 1024) {
          setFormError('גודל התמונה חייב להיות קטן מ-10MB');
          continue;
        }

        setIsLoading(true);

        const compressedFile = await compressImage(file);
        const base64 = await convertToBase64(compressedFile);

        const response = await axios.post(`${API_URL}/api/reports/upload`, {
          image: base64
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.data.url) {
          throw new Error('No URL received from server');
        }

        setNewTask(prev => ({
          ...prev,
          photos: [...prev.photos, {
            url: response.data.url,
            public_id: response.data.public_id,
            caption: ''
          }]
        }));

        setFormError('');
      } catch (error) {
        console.error('Error uploading photo:', error);
        setFormError(error.response?.data?.message || 'שגיאה בהעלאת תמונה');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePhotoCaption = (index, caption) => {
    setNewTask(prev => ({
      ...prev,
      photos: prev.photos.map((photo, i) => 
        i === index ? { ...photo, caption } : photo
      )
    }));
  };

  const removePhoto = (index) => {
    setNewTask(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_WIDTH = 1200;
          const MAX_HEIGHT = 1200;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height * MAX_WIDTH) / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width * MAX_HEIGHT) / height);
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', 0.7);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const renderPhotoUpload = () => (
    <div className="space-y-4">
      <label className="block text-sm font-medium text-gray-700">תמונות</label>
      <div className="flex items-center justify-center w-full">
        <label className="flex flex-col items-center justify-center w-full h-24 sm:h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
          <div className="flex flex-col items-center justify-center pt-3 pb-4">
            <Camera className="w-8 h-8 mb-2 text-gray-400" />
            <p className="text-xs sm:text-sm text-gray-500">לחץ להעלאת תמונה</p>
          </div>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            multiple
            onChange={handlePhotoUpload}
            className="hidden"
          />
        </label>
      </div>

      {newTask.photos.length > 0 && (
        <div className="grid grid-cols-2 gap-2 sm:gap-4">
          {newTask.photos.map((photo, index) => (
            <div key={index} className="relative">
              <img 
                src={photo.url} 
                alt={`תמונה ${index + 1}`} 
                className="w-full h-24 sm:h-32 object-cover rounded-lg"
              />
              <input
                type="text"
                placeholder="כיתוב לתמונה"
                value={photo.caption}
                onChange={(e) => handlePhotoCaption(index, e.target.value)}
                className="w-full p-1 sm:p-2 text-xs sm:text-sm border rounded-md mt-1"
              />
              <button
                type="button"
                onClick={() => removePhoto(index)}
                className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
              >
                <X size={14} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  const pendingTasks = tasks.filter(task => task.status === 'פתוח').length;
  const completedTasks = tasks.filter(task => task.status === 'הושלם').length;
  const inProgressTasks = tasks.filter(task => task.status === 'בביצוע').length;

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8" dir="rtl">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8">ניהול משימות</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-6 sm:mb-8">
        <StatCard title="משימות ממתינות" value={pendingTasks} icon={Clock} color="yellow" />
        <StatCard title="משימות בביצוע" value={inProgressTasks} icon={CheckCircle} color="blue" />
        <StatCard title="משימות שהושלמו" value={completedTasks} icon={CheckCircle} color="green" />
      </div>

      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg mb-6 sm:mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <h2 className="text-xl font-semibold mb-2 sm:mb-0">רשימת משימות</h2>
          <button 
            onClick={() => setIsPopupOpen(true)}
            className="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center text-sm"
          >
            <Plus className="h-4 w-4 mr-1" />
            הוסף משימה חדשה
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">כותרת</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך יצירה</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך יעד</th>
                <th className="px-3 sm:px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">תמונות</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תיאור</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tasks.map((task) => (
                <tr key={task._id}>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{task.title}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <select
                      value={task.status}
                      onChange={(e) => handleStatusChange(task._id, e.target.value)}
                      className={`px-2 py-1 rounded-full text-xs font-semibold 
                        ${task.status === 'הושלם' ? 'bg-green-100 text-green-800' : 
                          task.status === 'בביצוע' ? 'bg-blue-100 text-blue-800' : 
                          'bg-yellow-100 text-yellow-800'}`}
                    >
                      <option value="פתוח">פתוח</option>
                      <option value="בביצוע">בביצוע</option>
                      <option value="הושלם">הושלם</option>
                    </select>
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                    {new Date(task.createdAt).toLocaleDateString('he-IL')}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                    {task.dueDate ? new Date(task.dueDate).toLocaleDateString('he-IL') : 'לא נקבע'}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-center">
                    {task.photos && task.photos.length > 0 ? (
                      <div className="flex items-center justify-center">
                        <ImageIcon 
                          className="h-5 w-5 text-blue-500 hover:text-blue-600 transition-colors" 
                          title={`${task.photos.length} תמונות מצורפות`}
                        />
                        <span className="mr-1 text-xs text-blue-500">{task.photos.length}</span>
                      </div>
                    ) : (
                      <span className="text-gray-400">-</span>
                    )}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => setSelectedTask(task)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Info className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Task Description Popup */}
      {selectedTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">{selectedTask.title}</h3>
              <button 
                onClick={() => setSelectedTask(null)} 
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-4">
              {/* Status Badge */}
              <div className="flex items-center">
                <span className={`px-3 py-1 rounded-full text-sm font-semibold
                  ${selectedTask.status === 'הושלם' ? 'bg-green-100 text-green-800' : 
                    selectedTask.status === 'בביצוע' ? 'bg-blue-100 text-blue-800' : 
                    'bg-yellow-100 text-yellow-800'}`}>
                  {selectedTask.status}
                </span>
              </div>

              {/* Dates */}
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p className="text-gray-600">תאריך יצירה:</p>
                  <p className="font-medium">
                    {new Date(selectedTask.createdAt).toLocaleDateString('he-IL')}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600">תאריך יעד:</p>
                  <p className="font-medium">
                    {selectedTask.dueDate ? 
                      new Date(selectedTask.dueDate).toLocaleDateString('he-IL') : 
                      'לא נקבע'}
                  </p>
                </div>
              </div>

              {/* Description */}
              <div>
                <h4 className="text-sm font-medium text-gray-600 mb-2">תיאור המשימה:</h4>
                <p className="text-sm bg-gray-50 p-3 rounded-lg">{selectedTask.description}</p>
              </div>

              {/* Images Gallery */}
              {selectedTask.photos && selectedTask.photos.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium text-gray-600 mb-2">
                    תמונות מצורפות ({selectedTask.photos.length}):
                  </h4>
                  <div className="grid grid-cols-2 gap-4">
                    {selectedTask.photos.map((photo, index) => (
                      <div 
                        key={index} 
                        className="relative group bg-gray-100 rounded-lg overflow-hidden"
                      >
                        <img 
                          src={photo.url} 
                          alt={`תמונה ${index + 1}`} 
                          className="w-full h-48 object-cover hover:opacity-90 transition-opacity cursor-pointer"
                          onClick={() => window.open(photo.url, '_blank')}
                        />
                        {photo.caption && (
                          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
                            {photo.caption}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Branch Info */}
              <div>
                <p className="text-sm text-gray-600">סניף:</p>
                <p className="font-medium">{selectedTask.branch}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start p-4 z-50 overflow-y-auto">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-md my-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">הוסף משימה חדשה</h3>
              <button 
                onClick={() => setIsPopupOpen(false)} 
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {formError && (
              <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                {formError}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4 overflow-y-auto max-h-[calc(100vh-8rem)]">
              {/* Form fields */}
              <div className="space-y-4">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">כותרת</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={newTask.title}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-sm"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">תיאור</label>
                  <textarea
                    id="description"
                    name="description"
                    value={newTask.description}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-sm"
                    rows="3"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700 mb-1">תאריך יעד</label>
                  <input
                    type="date"
                    id="dueDate"
                    name="dueDate"
                    value={newTask.dueDate}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-sm"
                    required
                  />
                </div>

                {renderBranchSelect()}
                {renderPhotoUpload()}

                <div className="pt-4">
                  <button 
                    type="submit" 
                    className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 text-sm disabled:opacity-50"
                    disabled={isLoading}
                  >
                    {isLoading ? 'מוסיף משימה...' : 'הוסף משימה'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tasks;