import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import { Loader, AlertCircle, Calendar, ClipboardList, Users, TrendingUp, Info, X, ClipboardCheck, Building, Users2, Image as ImageIcon } from 'lucide-react';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, logout } from '../store/slices/authSlice';
import { motion } from 'framer-motion';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const getGradientColor = (color) => {
  const gradients = {
    blue: 'from-blue-500 to-blue-600',
    green: 'from-green-500 to-green-600',
    yellow: 'from-yellow-500 to-yellow-600',
    indigo: 'from-indigo-500 to-indigo-600',
  };
  return gradients[color] || gradients.blue;
};

const getStatusColor = (status) => {
  const colors = {
    'פתוח': 'bg-yellow-100 text-yellow-800',
    'בביצוע': 'bg-blue-100 text-blue-800',
    'הושלם': 'bg-green-100 text-green-800',
    'בוטל': 'bg-red-100 text-red-800',
  };
  return colors[status] || 'bg-gray-100 text-gray-800';
};

const requestTypeMapping = {
  'salary_increase': 'העלאת שכר',
  'warehouse_help': 'עזרה במחסן',
  'product_integrity': 'תקינות מוצרים',
  'staff_shortage': 'חוסר בעובדים',
  'vacation_days': 'ימי חופש',
  'other': 'אחר'
};

const itemTypeMapping = {
  'task': 'משימה',
  'visit': 'ביקור',
  'request': 'בקשה'
};

const DescriptionPopup = ({ item, onClose }) => {
  const formatDate = (dateString) => {
    if (!dateString) return 'לא נקבע';
    return new Date(dateString).toLocaleDateString('he-IL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{item.title}</h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="space-y-4">
          {/* Status Badge */}
          <div className="flex items-center">
            <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusColor(item.status)}`}>
              {item.status}
            </span>
          </div>

          {/* Description */}
          <div>
            <h4 className="text-sm font-medium text-gray-600 mb-2">תיאור:</h4>
            <p className="text-sm bg-gray-50 p-3 rounded-lg">{item.description}</p>
          </div>

          {/* Images Gallery - Only show for tasks with photos */}
          {item.photos && item.photos.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-gray-600 mb-2">
                תמונות מצורפות ({item.photos.length}):
              </h4>
              <div className="grid grid-cols-2 gap-4">
                {item.photos.map((photo, index) => (
                  <div 
                    key={index} 
                    className="relative group bg-gray-100 rounded-lg overflow-hidden"
                  >
                    <img 
                      src={photo.url} 
                      alt={`תמונה ${index + 1}`} 
                      className="w-full h-48 object-cover hover:opacity-90 transition-opacity cursor-pointer"
                      onClick={() => window.open(photo.url, '_blank')}
                    />
                    {photo.caption && (
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
                        {photo.caption}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Dates */}
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="text-gray-600">תאריך יצירה:</p>
              <p className="font-medium">
                {formatDate(item.createdAt)}
              </p>
            </div>
            <div>
              <p className="text-gray-600">תאריך יעד:</p>
              <p className="font-medium">
                {formatDate(item.dueDate)}
              </p>
            </div>
          </div>

          {/* Branch Info */}
          {item.branch && (
            <div>
              <p className="text-sm text-gray-600">סניף:</p>
              <p className="font-medium">{item.branch}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingSpinner = () => {
  const loadingSteps = [
    "טוען נתוני משימות",
    "טוען נתוני ביקורים",
    "טוען בקשות עובדים",
    "טוען דוחות"
  ];

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-blue-50 to-white">
      <motion.div
        className="relative w-32 h-32"
        animate={{
          rotate: 360
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "linear"
        }}
      >
        {[...Array(3)].map((_, index) => (
          <motion.span
            key={index}
            className="absolute w-full h-full border-4 border-transparent border-t-blue-500 rounded-full"
            style={{
              scale: 1 - index * 0.15,
              opacity: 1 - index * 0.2,
            }}
            animate={{
              rotate: 360,
              scale: [(1 - index * 0.15), (0.9 - index * 0.15), (1 - index * 0.15)],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
              delay: index * 0.2
            }}
          />
        ))}
      </motion.div>

      <motion.div
        className="mt-8 space-y-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        {loadingSteps.map((step, index) => (
          <motion.div
            key={index}
            className="flex items-center space-x-2 text-gray-600"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <motion.div
              className="w-2 h-2 bg-blue-500 rounded-full"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [1, 0.5, 1],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                delay: index * 0.2
              }}
            />
            <span className="text-sm">{step}</span>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className="mt-6 text-blue-600 font-medium"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        מאחזר את הנתונים שלך...
      </motion.div>
    </div>
  );
};

const DashboardContent = () => {
  const [dashboardData, setDashboardData] = useState({
    tasks: [],
    visits: [],
    employeeRequests: [],
    endOfDayReports: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [adminStats, setAdminStats] = useState({
    totalReports: 0,
    totalManagers: 0,
    mostActiveStats: { branch: '', reportCount: 0, averageScore: 0 }
  });

  const user = useSelector(state => state.auth.user);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`
        };

        const [tasksRes, visitsRes, requestsRes, reportsRes] = await Promise.all([
          axios.get(`${API_URL}/api/tasks`, { headers }),
          axios.get(`${API_URL}/api/visits`, { headers }),
          axios.get(`${API_URL}/api/employee-requests`, { headers }),
          axios.get(`${API_URL}/api/reports/user`, { headers })
        ]);

        console.log('Tasks:', tasksRes.data);
        console.log('Visits:', visitsRes.data);
        console.log('Requests:', requestsRes.data);
        console.log('Reports:', reportsRes.data);

        console.log('Raw Visits Data:', visitsRes.data);

        setDashboardData({
          tasks: tasksRes.data || [],
          visits: visitsRes.data || [],
          employeeRequests: requestsRes.data || [],
          endOfDayReports: reportsRes.data || [],
        });
        setError('');
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('שגיאה בטעינת נתוני לוח המחוונים');
        if (error.response?.status === 401) {
          dispatch(logout());
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    if (token && user) {
      fetchDashboardData();
    }
  }, [token, user, dispatch, navigate]);

  useEffect(() => {
    const fetchAdminStats = async () => {
      if (user?.role === 'admin') {
        try {
          const response = await axios.get(`${API_URL}/api/admin/dashboard-stats`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setAdminStats(response.data);
        } catch (error) {
          console.error('Error fetching admin stats:', error);
        }
      }
    };

    if (token && user?.role === 'admin') {
      fetchAdminStats();
    }
  }, [token, user]);

  const COLORS = ['#4C51BF', '#48BB78', '#ECC94B', '#ED64A6'];

  const StatCard = ({ title, value, subtitle, icon: Icon, color, onClick }) => (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={`bg-gradient-to-r ${getGradientColor(color)} p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <h3 className="text-white text-lg font-semibold mb-2">{title}</h3>
          <p className="text-white text-2xl font-bold">{value}</p>
          {subtitle && (
            <p className="text-white/80 text-sm mt-1">{subtitle}</p>
          )}
        </div>
        <div className="bg-white/20 p-3 rounded-lg">
          <Icon className="h-8 w-8 text-white" />
        </div>
      </div>
    </motion.div>
  );

  const renderLineChart = (data) => {
    const chartData = {
      labels: data.map(item => item.date),
      datasets: [
        {
          label: 'ציון ממוצע',
          data: data.map(item => item.score),
          borderColor: '#4C51BF',
          backgroundColor: 'rgba(76, 81, 191, 0.1)',
          fill: true,
          tension: 0.4,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: true, position: 'top' },
        tooltip: { 
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: '#FFFFFF',
          bodyColor: '#FFFFFF',
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 10,
          ticks: {
            stepSize: 2,
          },
        },
      },
    };

    return (
      <div className="h-80">
        <Line data={chartData} options={options} />
      </div>
    );
  };

  const renderPieChart = (data) => {
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          data: data.map(item => item.value),
          backgroundColor: COLORS,
          borderColor: 'white',
          borderWidth: 2,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 15,
            padding: 15,
            font: {
              size: 12,
            },
          },
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: 'white',
          bodyColor: 'white',
          borderColor: 'white',
          borderWidth: 1,
          callbacks: {
            label: (context) => `${context.label}: ${context.parsed.toFixed(2)}`,
          },
        },
      },
    };

    return (
      <div className="h-[200px] sm:h-[300px]">
        <Pie data={chartData} options={options} />
      </div>
    );
  };

  const openPopup = (item, type) => {
    setSelectedItem({ ...item, type });
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'לא נקבע';
    return new Date(dateString).toLocaleDateString('he-IL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  const pendingTasks = dashboardData.tasks.filter(task => task.status !== 'הושלם').length;
  const upcomingVisits = dashboardData.visits.filter(visit => {
    const visitDate = new Date(visit.visitDate);
    visitDate.setHours(0, 0, 0, 0);
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    console.log('Visit Date:', visitDate, 'Today:', today, 'Is Future:', visitDate >= today);
    return visitDate >= today;
  }).length;
  const pendingRequests = dashboardData.employeeRequests.filter(request => request.status === 'ממתין לאישור').length;

  const averageScores = dashboardData.endOfDayReports.length > 0 
    ? dashboardData.endOfDayReports.reduce((acc, report) => {
        acc.storageArrangement += report.storageArrangementScore || 0;
        acc.checkLevel += report.checkLevelScore || 0;
        acc.warehousemanStation += report.warehousemanStationScore || 0;
        acc.returnsCorner += report.returnsCornerScore || 0;
        return acc;
      }, { storageArrangement: 0, checkLevel: 0, warehousemanStation: 0, returnsCorner: 0 })
    : { storageArrangement: 0, checkLevel: 0, warehousemanStation: 0, returnsCorner: 0 };

  const reportsCount = dashboardData.endOfDayReports.length;
  Object.keys(averageScores).forEach(key => {
    averageScores[key] = (averageScores[key] / reportsCount).toFixed(2);
  });

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8" dir="rtl">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8">סקירת לוח מחוונים</h1>
      
      {user?.role === 'admin' && (
        <>
          <h2 className="text-xl font-semibold text-gray-700 mb-4">סטטיסטיקות מערכת</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            <StatCard 
              title="סה״כ דוחות" 
              value={adminStats.totalReports} 
              icon={ClipboardCheck}
              color="blue"
            />
            <StatCard 
              title="מנהלים פעילים" 
              value={adminStats.totalManagers} 
              icon={Users2}
              color="green"
            />
            <StatCard 
              title="סניף מוביל בביקורות" 
              value={adminStats.mostActiveStats.branch}
              subtitle={`${adminStats.mostActiveStats.reportCount} ביקורות | ציון ממוצע ${adminStats.mostActiveStats.averageScore}`}
              icon={Building}
              color="yellow"
            />
          </div>
        </>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard 
          title="משימות ממתינות" 
          value={pendingTasks} 
          icon={ClipboardList} 
          color="yellow" 
          onClick={() => navigate('/dashboard/tasks')}
        />
        <StatCard 
          title="ביקורים קרובים" 
          value={upcomingVisits} 
          icon={Calendar} 
          color="blue" 
          onClick={() => navigate('/dashboard/future-visits')}
        />
        <StatCard 
          title="בקשות ממתינות" 
          value={pendingRequests} 
          icon={Users} 
          color="green" 
          onClick={() => navigate('/dashboard/employee-requests')}
        />
        <StatCard 
          title="ציון מחסן ממוצע" 
          value={averageScores.storageArrangement} 
          icon={TrendingUp} 
          color="indigo" 
          onClick={() => navigate('/dashboard/reports')}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="backdrop-blur-md bg-white/80 p-6 rounded-xl shadow-lg border border-gray-200/50">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">מגמת ציוני מחסן</h2>
          {renderLineChart(dashboardData.endOfDayReports.map(report => ({
            date: new Date(report.date).toLocaleDateString('he-IL'),
            score: (report.storageArrangementScore + report.checkLevelScore + report.warehousemanStationScore + report.returnsCornerScore) / 4
          })))}
        </div>
        <div className="backdrop-blur-md bg-white/80 p-6 rounded-xl shadow-lg border border-gray-200/50">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">התפלגות ציונים ממוצעים</h2>
          {renderPieChart([
            { name: 'סידור מחסן', value: parseFloat(averageScores.storageArrangement) },
            { name: 'רמ בדיקה', value: parseFloat(averageScores.checkLevel) },
            { name: 'עמדת מחסנאי', value: parseFloat(averageScores.warehousemanStation) },
            { name: 'פינת חזרות', value: parseFloat(averageScores.returnsCorner) },
          ])}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded-xl shadow-lg overflow-hidden">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">משימות אחרונות</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">כותרת</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך יצירה</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך יעד</th>
                  <th className="px-3 sm:px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">תמונות</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תיאור</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {dashboardData.tasks
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .slice(0, 5)
                  .map((task, index) => (
                    <motion.tr
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      key={index}
                      className="hover:bg-gray-50 transition-colors duration-200"
                    >
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{task.title}</td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(task.status)}`}>
                          {task.status}
                        </span>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                        {new Date(task.createdAt).toLocaleDateString('he-IL')}
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                        {task.dueDate ? new Date(task.dueDate).toLocaleDateString('he-IL') : 'לא נקבע'}
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-center">
                        {task.photos && task.photos.length > 0 ? (
                          <div className="flex items-center justify-center">
                            <ImageIcon 
                              className="h-5 w-5 text-blue-500 hover:text-blue-600 transition-colors" 
                              title={`${task.photos.length} תמונות מצורפות`}
                            />
                            <span className="mr-1 text-xs text-blue-500">{task.photos.length}</span>
                          </div>
                        ) : (
                          <span className="text-gray-400">-</span>
                        )}
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() => openPopup(task, 'task')}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Info className="h-5 w-5" />
                        </button>
                      </td>
                    </motion.tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-lg overflow-hidden">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">ביקורים קרובים</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך ביקור</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מטרת ביקור</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                  <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">פרטים</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {dashboardData.visits
                  .filter(visit => {
                    const visitDate = new Date(visit.visitDate);
                    visitDate.setHours(0, 0, 0, 0);
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return visitDate >= today;
                  })
                  .sort((a, b) => new Date(a.visitDate) - new Date(b.visitDate))
                  .slice(0, 5)
                  .map((visit, index) => (
                    <motion.tr
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="hover:bg-gray-50 transition-colors duration-200"
                    >
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                        {visit.branch}
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                        {new Date(visit.visitDate).toLocaleDateString('he-IL')}
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                        {visit.visitPurpose}
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(visit.status)}`}>
                          {visit.status}
                        </span>
                      </td>
                      <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() => openPopup(visit, 'visit')}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Info className="h-5 w-5" />
                        </button>
                      </td>
                    </motion.tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-lg overflow-hidden">
        <h2 className="text-lg font-semibold mb-4 text-gray-800">בקשות עובדים אחרונות</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">עובד</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סוג בקשה</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תיאור</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dashboardData.employeeRequests
                .sort((a, b) => new Date(b.createdAt || b.requestDate) - new Date(a.createdAt || a.requestDate))
                .slice(0, 5)
                .map((request, index) => (
                  <motion.tr
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    key={index}
                    className="hover:bg-gray-50 transition-colors duration-200"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm">{request.employeeName}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {requestTypeMapping[request.requestType] || request.requestType}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">{request.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {formatDate(request.createdAt || request.requestDate)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => openPopup(request, 'request')}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <Info className="h-5 w-5" />
                      </button>
                    </td>
                  </motion.tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedItem && (
        <DescriptionPopup
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
};

export default DashboardContent;