import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config';

export const useBranchManagement = () => {
  const [newBranch, setNewBranch] = useState('');
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
    } catch (err) {
      setError('שגיאה בטעינת רשימת הסניפים');
    }
  };

  const handleNewBranchChange = (e) => {
    setNewBranch(e.target.value);
    setError('');
    setSuccess('');
  };

  const addNewBranch = async () => {
    if (!newBranch.trim()) return;
    
    setIsLoading(true);
    try {
      await axios.post(
        `${API_URL}/api/branches`,
        { name: newBranch },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }}
      );
      setSuccess('הסניף נוסף בהצלחה');
      setNewBranch('');
      fetchBranches();
    } catch (err) {
      setError(err.response?.data?.message || 'שגיאה בהוספת הסניף');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBranch = async (branchId) => {
    if (!window.confirm('האם אתה בטוח שברצונך למחוק את הסניף?')) return;

    try {
      await axios.delete(`${API_URL}/api/branches/${branchId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSuccess('הסניף נמחק בהצלחה');
      fetchBranches();
    } catch (err) {
      setError(err.response?.data?.message || 'שגיאה במחיקת הסניף');
    }
  };

  return {
    newBranch,
    branches,
    handleNewBranchChange,
    addNewBranch,
    deleteBranch,
    searchTerm,
    setSearchTerm,
    error,
    success,
    isLoading
  };
};