import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const SalesCard = ({ title, children, className = '', stepNumber }) => (
  <div className="relative">
    {stepNumber && (
      <div className="absolute -top-3 right-4 z-10">
        <div className="text-sm font-bold bg-blue-600 text-white rounded-full px-4 py-1 shadow-md">
          שלב {stepNumber}
        </div>
      </div>
    )}
    <div className={`sales-card rounded-2xl p-4 sm:p-6 shadow-lg mb-6 sm:mb-8 relative overflow-hidden mt-4 ${className}`}>
      <h2 className="text-xl sm:text-2xl font-bold text-blue-600 mb-4">{title}</h2>
      {children}
    </div>
  </div>
);

const ContentCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-xl p-4 shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-100">
    <div className="flex items-center gap-2 mb-3">
      {icon && <span className="text-xl">{icon}</span>}
      <h3 className="font-bold text-base sm:text-lg text-gray-800">{title}</h3>
    </div>
    <div className="text-gray-600 text-sm sm:text-base">{children}</div>
  </div>
);

const CashierGuide = () => {
  const cardsRef = useRef([]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  useEffect(() => {
    // Set meta tags when component mounts
    document.title = 'מדריך המכירות המנצח | Yellow App';
    
    // Add noindex meta tag to prevent search engine indexing
    const noIndexMeta = document.createElement('meta');
    noIndexMeta.name = 'robots';
    noIndexMeta.content = 'noindex, nofollow';
    document.head.appendChild(noIndexMeta);

    // Add OG meta tags
    const ogTags = [
      { property: 'og:title', content: 'מדריך המכירות המנצח | Yellow App' },
      { property: 'og:description', content: 'מדריך מקיף להגדלת התקנות אפליקציית Yellow' },
      { property: 'og:type', content: 'article' }
    ];

    const metaTags = ogTags.map(tag => {
      const meta = document.createElement('meta');
      meta.setAttribute('property', tag.property);
      meta.setAttribute('content', tag.content);
      return meta;
    });

    metaTags.forEach(tag => document.head.appendChild(tag));

    // Cleanup function to remove meta tags when component unmounts
    return () => {
      document.head.removeChild(noIndexMeta);
      metaTags.forEach(tag => document.head.removeChild(tag));
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white flex flex-col">
      <div className="flex-grow">
        <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-sm shadow-sm">
          <div className="max-w-5xl mx-auto px-4 py-4">
            <h1 className="text-2xl sm:text-4xl font-bold text-gray-800 text-center">
              מדריך המכירות המנצח 🚀
            </h1>
            <p className="text-center text-gray-600 mt-2">
            איך להגדיל התקנות אפליקציית Yellow ב-3 צעדים פשוטים
            </p>
          </div>
        </div>

        <motion.div className="max-w-5xl mx-auto px-4 py-6">
          <motion.div variants={cardVariants}>
            <SalesCard title="👋 זיהוי הזדמנות והתחלת שיחה" stepNumber="1">
              <div className="grid sm:grid-cols-2 gap-4">
                <ContentCard title="פתיחה מנצחת" icon="✅">
                  <div className="p-3 bg-green-50 rounded-lg">
                    <p className="font-medium">"שמתי לב שיש לך פה מוצרים ב[X] שקלים - אפשר לחסוך לך [5%] מזה ישר עכשיו"</p>
                    <p className="text-sm text-green-600 mt-2">* תמיד להתחיל עם סכום ספציפי שהלקוח יכול לחסוך</p>
                  </div>
                </ContentCard>
                <ContentCard title="טעויות נפוצות" icon="❌">
                  <div className="p-3 bg-red-50 rounded-lg">
                    <ul className="space-y-2">
                      <li>❌ "יש לך את האפליקציה שלנו?"</li>
                      <li>❌ "רוצה להוריד את האפליקציה?"</li>
                      <li>❌ שאלות סגורות שקל לענות עליהן 'לא'</li>
                    </ul>
                  </div>
                </ContentCard>
              </div>
            </SalesCard>
          </motion.div>

          <motion.div variants={cardVariants}>
            <SalesCard title="💰 הצגת ערך מיידי" stepNumber="2">
              <div className="grid sm:grid-cols-2 gap-4">
                <ContentCard title="הדגמה מעשית" icon="📱">
                  <div className="p-3 bg-blue-50 rounded-lg">
                    <p className="font-medium">"בוא/י אראה לך משהו מעניין..."</p>
                    <ul className="space-y-2 mt-2">
                      <li>1. הראה/י את המחיר הרגיל</li>
                      <li>2. הראה/י את המחיר באפליקציה</li>
                      <li>3. הדגש/י: "זה חיסכון של __ ₪ רק על הקנייה הזו!"</li>
                    </ul>
                  </div>
                </ContentCard>
                <ContentCard title="יצירת דחיפות" icon="⏰">
                  <div className="p-3 bg-yellow-50 rounded-lg">
                    <p className="font-medium">"המבצעים האלה מתחלפים כל יום - בוא/י נוריד עכשיו כדי שתספיק/י לנצל את ההטבות"</p>
                  </div>
                </ContentCard>
              </div>
            </SalesCard>
          </motion.div>

          <motion.div variants={cardVariants}>
            <SalesCard title="🎯 סגירה והתקנה" stepNumber="3">
              <div className="grid sm:grid-cols-2 gap-4">
                <ContentCard title="סגירה אקטיבית" icon="✨">
                  <div className="p-3 bg-green-50 rounded-lg">
                    <p className="font-medium">"בוא/י נוריד את האפליקציה ביחד - אני אדאג שתקבל/י את כל ההטבות מיד"</p>
                    <ul className="space-y-2 mt-2 text-sm">
                      <li>• להישאר עם הלקוח עד להתקנה מלאה</li>
                      <li>• לוודא שההטבות מופיעות</li>
                      <li>• להראות איך משתמשים</li>
                    </ul>
                  </div>
                </ContentCard>
                <ContentCard title="התמודדות עם התנגדויות" icon="🛡️">
                  <div className="p-3 bg-purple-50 rounded-lg">
                    <ul className="space-y-2">
                      <li>"אין לי מקום" - "בוא/י נמחק יחד אפליקציה שלא בשימוש"</li>
                      <li>"אין לי זמן" - "זה ייקח רק דקה ויחסוך לך כסף כבר עכשיו"</li>
                      <li>"אני אעשה את זה בבית" - "בוא/י נעשה עכשיו ותרוויח/י כבר בקנייה הזו"</li>
                    </ul>
                  </div>
                </ContentCard>
              </div>
            </SalesCard>
          </motion.div>

          <motion.div variants={cardVariants}>
            <SalesCard title="💡 טיפים מנצחים" className="warning-tip">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <ContentCard title="שפת גוף" icon="🤝">
                  <ul className="space-y-2 list-none">
                    <li className="p-2 bg-amber-50 rounded-lg">להישאר ליד הלקוח</li>
                    <li className="p-2 bg-amber-50 rounded-lg">חיוך ואנרגיה חיובית</li>
                    <li className="p-2 bg-amber-50 rounded-lg">להראות התלהבות אמיתית</li>
                  </ul>
                </ContentCard>
                <ContentCard title="מילות מפתח" icon="🎯">
                  <ul className="space-y-2 list-none">
                    <li className="p-2 bg-amber-50 rounded-lg">"חיסכון מיידי"</li>
                    <li className="p-2 bg-amber-50 rounded-lg">"כבר בקנייה הזו"</li>
                    <li className="p-2 bg-amber-50 rounded-lg">"בוא/י נעשה ביחד"</li>
                  </ul>
                </ContentCard>
                <ContentCard title="מדדי הצלחה" icon="📊">
                  <ul className="space-y-2 list-none">
                    <li className="p-2 bg-amber-50 rounded-lg">התקנה מלאה</li>
                    <li className="p-2 bg-amber-50 rounded-lg">הפעלה ראשונית</li>
                    <li className="p-2 bg-amber-50 rounded-lg">שימוש בהטבה ראשונה</li>
                  </ul>
                </ContentCard>
              </div>
            </SalesCard>
          </motion.div>
        </motion.div>
      </div>

      <footer className="py-4 px-4 text-center text-gray-600 text-sm border-t border-gray-200 bg-white/50 backdrop-blur-sm mt-8">
        <p className="font-medium">
          פותח ע״י ישי אלמולי © {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  );
};

export default CashierGuide; 