import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Warehouse, Award, TrendingUp, Users } from 'lucide-react';

const Homepage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col justify-center items-center p-8">
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gray-800 p-8 text-white text-center">
          <Warehouse className="h-16 w-16 mx-auto mb-4" />
          <h1 className="text-5xl font-bold mb-2">מערכת ניהול תפעול</h1>
          <p className="text-xl">פתרון כולל לניהול מחסנים, צוותי עבודה ותהליכי תפעול</p>
        </div>
        
        <div className="p-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">יתרונות המערכת</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-start">
              <Award className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">ניהול דוחות יומיים</h3>
                <p className="text-gray-600">מעקב ובקרה אחר פעילות יומית, כולל דיווחי ביצוע, משימות ותקלות</p>
              </div>
            </div>
            <div className="flex items-start">
              <TrendingUp className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">ניהול משימות וביקורים</h3>
                <p className="text-gray-600">מערכת חכמה לניהול משימות שוטפות, ביקורים מתוכננים ומעקב אחר ביצוע</p>
              </div>
            </div>
            <div className="flex items-start">
              <Users className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">ניהול בקשות עובדים</h3>
                <p className="text-gray-600">מערכת מתקדמת לטיפול בבקשות עובדים, כולל בקשות שכר והערכות ביצועים</p>
              </div>
            </div>
            <div className="flex items-start">
              <Warehouse className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">יעדים ומדדי ביצוע</h3>
                <p className="text-gray-600">מעקב אחר יעדים חודשיים, ניתוח ביצועים ומדידת הצלחה</p>
              </div>
            </div>
          </div>
          
          {/* System Overview Section */}
          <div className="mt-8 bg-gray-100 p-6 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4 text-center">ניהול תפעולי חכם</h2>
            <p className="text-gray-700 text-center">
              המערכת מאפשרת ניהול יעיל של תהליכי תפעול, מעקב אחר משימות יומיות, וטיפול בבקשות עובדים.
              באמצעות ממשק ידידותי, מנהלים יכולים לקבל תמונת מצב מלאה על פעילות הצוות ולקבל החלטות מושכלות.
            </p>
          </div>
          
          <div className="mt-8 text-center">
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-3 px-8 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 shadow-lg"
            >
              התחברות למערכת
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 text-center text-gray-600">
        נבנה ע"י ישי אלמולי
      </div>
    </div>
  );
};

export default Homepage;