import React, { useState, useEffect } from 'react';
import { Copy, Download, Trash, Check, X, Clock } from 'lucide-react';
import { toast } from 'react-hot-toast';
import api from '../../utils/api';

const STATUS_TYPES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied'
};

const STATUS_LABELS = {
  [STATUS_TYPES.PENDING]: 'ממתין',
  [STATUS_TYPES.APPROVED]: 'אושר',
  [STATUS_TYPES.DENIED]: 'נדחה'
};

const STATUS_COLORS = {
  [STATUS_TYPES.PENDING]: 'bg-yellow-100 text-yellow-800',
  [STATUS_TYPES.APPROVED]: 'bg-green-100 text-green-800',
  [STATUS_TYPES.DENIED]: 'bg-red-100 text-red-800'
};

const SalaryUpgradeSurvey = () => {
  const [loading, setLoading] = useState(true);
  const [responses, setResponses] = useState([]);
  const [analytics, setAnalytics] = useState({
    totalThisMonth: 0,
    topBranch: { name: '', count: 0 },
    totalPending: 0,
    statusCounts: {
      pending: 0,
      approved: 0,
      denied: 0
    }
  });

  useEffect(() => {
    fetchResponses();
  }, []);

  useEffect(() => {
    calculateAnalytics();
  }, [responses]);

  const calculateAnalytics = () => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    const thisMonthResponses = responses.filter(r => 
      new Date(r.createdAt) >= firstDayOfMonth
    );

    const branchCounts = responses.reduce((acc, r) => {
      acc[r.branchId.name] = (acc[r.branchId.name] || 0) + 1;
      return acc;
    }, {});

    const topBranch = Object.entries(branchCounts)
      .sort(([,a], [,b]) => b - a)[0] || ['', 0];

    const statusCounts = responses.reduce((acc, r) => {
      acc[r.status] = (acc[r.status] || 0) + 1;
      return acc;
    }, {});

    setAnalytics({
      totalThisMonth: thisMonthResponses.length,
      topBranch: { name: topBranch[0], count: topBranch[1] },
      totalPending: responses.length,
      statusCounts: {
        pending: statusCounts[STATUS_TYPES.PENDING] || 0,
        approved: statusCounts[STATUS_TYPES.APPROVED] || 0,
        denied: statusCounts[STATUS_TYPES.DENIED] || 0
      }
    });
  };

  const fetchResponses = async () => {
    try {
      const response = await api.get('/api/salary-surveys/responses');
      setResponses(response.data);
    } catch (error) {
      console.error('Error fetching responses:', error);
      toast.error('שגיאה בטעינת הנתונים');
    } finally {
      setLoading(false);
    }
  };

  const copyLink = () => {
    const link = `${window.location.origin}/salary-survey`;
    navigator.clipboard.writeText(link);
    toast.success('הקישור הועתק ללוח');
  };

  const downloadCSV = async () => {
    try {
      toast.loading('מכין קובץ להורדה...', { id: 'downloadCSV' });
      
      if (responses.length === 0) {
        toast.error('אין נתונים להורדה', { id: 'downloadCSV' });
        return;
      }

      const headers = [
        'סניף',
        'תאריך הגשה',
        'שם העובד',
        'מספר עובד',
        'תפקיד',
        'סוג שכר',
        'משכורת נוכחית',
        'ותק בחברה',
        'נימוק לבקשה'
      ];

      const csvRows = responses.map(response => [
        response.branchId.name,
        new Date(response.createdAt).toLocaleDateString('he-IL'),
        response.employeeName,
        response.employeeNumber,
        response.role,
        response.salaryType === 'hourly' ? 'שעתי' : 'גלובלי',
        response.currentSalary,
        `${response.yearsInCompany} שנים`,
        response.justification?.replace(/,/g, ';').replace(/\n/g, ' ')
      ]);

      const csvContent = [
        headers.join(','),
        ...csvRows.map(row => row.join(','))
      ].join('\n');

      const blob = new Blob(["\ufeff" + csvContent], {
        type: 'text/csv;charset=utf-8;'
      });
      
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', `salary-requests-${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success('הקובץ הורד בהצלחה', { id: 'downloadCSV' });
    } catch (error) {
      console.error('Error downloading CSV:', error);
      toast.error('שגיאה בהורדת הקובץ', { id: 'downloadCSV' });
    }
  };

  const deleteResponse = async (responseId) => {
    if (!window.confirm('האם אתה בטוח שברצונך למחוק את הבקשה?')) {
      return;
    }

    try {
      toast.loading('מוחק בקשה...', { id: 'deleteResponse' });
      await api.delete(`/api/salary-surveys/response/${responseId}`);
      setResponses(prevResponses => 
        prevResponses.filter(response => response._id !== responseId)
      );
      toast.success('הבקשה נמחקה בהצלחה', { id: 'deleteResponse' });
    } catch (error) {
      console.error('Error deleting response:', error);
      toast.error('שגיאה במחיקת הבקשה', { id: 'deleteResponse' });
    }
  };

  const updateStatus = async (responseId, newStatus) => {
    try {
      toast.loading('מעדכן סטטוס...', { id: 'updateStatus' });
      await api.patch(`/api/salary-surveys/response/${responseId}/status`, { status: newStatus });
      
      setResponses(prevResponses => 
        prevResponses.map(response => 
          response._id === responseId 
            ? { ...response, status: newStatus }
            : response
        )
      );
      
      toast.success('הסטטוס עודכן בהצלחה', { id: 'updateStatus' });
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('שגיאה בעדכון הסטטוס', { id: 'updateStatus' });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
        <div className="card md:col-span-2">
          <h3 className="text-sm font-medium text-gray-500">בקשות החודש</h3>
          <p className="text-2xl font-bold text-blue-600 mt-2">{analytics.totalThisMonth}</p>
        </div>
        
        <div className="card md:col-span-2">
          <h3 className="text-sm font-medium text-gray-500">סניף מוביל</h3>
          <p className="text-2xl font-bold text-purple-600 mt-2">
            {analytics.topBranch.name || 'אין נתונים'}
          </p>
          <p className="text-sm text-gray-500 mt-1">
            {analytics.topBranch.count} בקשות
          </p>
        </div>

        <div className="card md:col-span-2">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-yellow-600">
                <Clock className="h-4 w-4 inline mr-1" />
                ממתין
              </span>
              <span className="font-bold">{analytics.statusCounts.pending}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-green-600">
                <Check className="h-4 w-4 inline mr-1" />
                אושר
              </span>
              <span className="font-bold">{analytics.statusCounts.approved}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-red-600">
                <X className="h-4 w-4 inline mr-1" />
                נדחה
              </span>
              <span className="font-bold">{analytics.statusCounts.denied}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">ניהול בקשות העלאת שכר</h2>
          <div className="space-x-2">
            <button
              onClick={copyLink}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2"
            >
              <Copy className="h-5 w-5" />
              העתק קישור לטופס
            </button>
            <button
              onClick={downloadCSV}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors flex items-center gap-2"
            >
              <Download className="h-5 w-5" />
              הורד לאקסל
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך הגשה</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם העובד</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מספר עובד</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תפקיד</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סוג שכר</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">משכורת נוכחית</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ותק</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">פעולות</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {responses.map((response) => (
                <tr key={response._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">{response.branchId.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(response.createdAt).toLocaleDateString('he-IL')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{response.employeeName}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{response.employeeNumber}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{response.role}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {response.salaryType === 'hourly' ? 'שעתי' : 'גלובלי'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">₪{Number(response.currentSalary).toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{response.yearsInCompany} שנים</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <select
                      value={response.status || STATUS_TYPES.PENDING}
                      onChange={(e) => updateStatus(response._id, e.target.value)}
                      className={`px-2 py-1 rounded-full text-xs font-semibold ${STATUS_COLORS[response.status || STATUS_TYPES.PENDING]}`}
                    >
                      <option value={STATUS_TYPES.PENDING}>ממתין</option>
                      <option value={STATUS_TYPES.APPROVED}>אושר</option>
                      <option value={STATUS_TYPES.DENIED}>נדחה</option>
                    </select>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-2">
                    <button
                      onClick={() => deleteResponse(response._id)}
                      className="text-gray-600 hover:text-gray-900 transition-colors"
                      title="מחק בקשה"
                    >
                      <Trash className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalaryUpgradeSurvey;