import React, { useState, useEffect } from 'react';
import { NavLink, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice';
import { LayoutDashboard, FileText, BarChart3, BarChart2, ClipboardList, Calendar, Users, UserPlus, Menu, X, User, LogOut, Brain, FileQuestion, Building } from 'lucide-react';
import DashboardContent from './DashboardContent';
import EndOfDayForm from './EndOfDayForm';
import { ReportsList } from './reports';
import AdminPanel from './AdminPanel';
import Tasks from './Tasks';
import FutureVisits from './FutureVisits';
import EmployeeRequests from './EmployeeRequests';
import UserProfile from './UserProfile';
import AIAnalyzerContent from './AIReportAnalysis'; 
import MonthlyGoalsDashboard from './MonthlyGoalsDashboard';
import ReportsManagement from './ReportsManagement';
import UserManagement from './AdminPanel/UserManagement';
import SurveyManagement from './AdminPanel/SurveyManagement';
import SurveyResults from './AdminPanel/SurveyResults';
import BranchManagement from './AdminPanel/BranchManagement';
import SalaryUpgradeSurvey from './AdminPanel/SalaryUpgradeSurvey';

const ModernDashboardLayout = ({ user, onLogout }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navItems = [
    { path: '/dashboard', icon: LayoutDashboard, text: 'דשבורד' },
    { path: '/dashboard/end-of-day', icon: FileText, text: 'הגשת דו"ח יומי' },
    { path: '/dashboard/reports', icon: BarChart3, text: 'דוחות' },
    { path: '/dashboard/tasks', icon: ClipboardList, text: 'משימות' },
    { path: '/dashboard/future-visits', icon: Calendar, text: 'ביקורים עתידיים' },
    { path: '/dashboard/employee-requests', icon: Users, text: 'בקשות מעובדים' },
    { path: '/dashboard/ai-analyzer', icon: Brain, text: 'מנתח AI' },
    ...(user.role === 'admin' ? [
      { type: 'divider', text: 'כלי מנהל מערכת' },
      { path: '/dashboard/admin/reports-management', icon: ClipboardList, text: 'ניהול דוחות' },
      { path: '/dashboard/admin/surveys', icon: FileQuestion, text: 'ניהול סקרים מנהל תחום מחסנים' },
      { path: '/dashboard/admin/salary-surveys', icon: FileQuestion, text: 'סקרי העלאות שכר' },
      { type: 'divider', text: 'הגדרות מערכת' },
      { path: '/dashboard/admin/users', icon: Users, text: 'ניהול משתמשים' },
      { path: '/dashboard/admin/branches', icon: Building, text: 'ניהול סניפים' }
    ] : []),
  ];

  useEffect(() => {
    console.log('ModernDashboardLayout mounted');
    console.log('User:', user);
    console.log('Current user role:', user.role);
    console.log('Is admin?:', user.role === 'admin');
    return () => console.log('ModernDashboardLayout unmounted');
  }, [user]);

  useEffect(() => {
    console.log('Nav Items:', navItems);
  }, [navItems, user.role]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch(logout());
    onLogout();
    navigate('/login');
  };

  // Enhanced NavLink component
  const NavItem = ({ path, icon: Icon, text, type }) => {
    if (type === 'divider') {
      return (
        <div className="px-6 py-3">
          <div className="flex items-center">
            <div className="flex-grow border-t border-gray-300/30"></div>
            <span className="mx-4 text-sm text-gray-300 font-medium">{text}</span>
            <div className="flex-grow border-t border-gray-300/30"></div>
          </div>
        </div>
      );
    }

    return (
      <NavLink
        to={path}
        className={({ isActive }) => `
          flex items-center px-6 py-4 transition-all duration-300 ease-in-out
          ${isActive 
            ? 'bg-white/10 text-white border-r-4 border-blue-300 shadow-lg backdrop-blur-sm' 
            : 'text-blue-100 hover:bg-white/5 hover:text-white'}
          group rounded-l-xl mr-2 space-x-3
        `}
        onClick={() => setIsSidebarOpen(false)}
      >
        <Icon className="h-5 w-5 ml-3 transition-transform duration-300 group-hover:scale-110" />
        <span className="text-sm font-medium tracking-wide">{text}</span>
      </NavLink>
    );
  };

  if (!user) {
    console.error('User is not defined');
    return <div>Error: User not found</div>;
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50 font-sans" dir="rtl">
      {/* Enhanced Sidebar */}
      <aside 
        className={`
          bg-gradient-to-br from-blue-800 via-blue-700 to-blue-800
          text-white w-72 fixed top-0 bottom-0 right-0
          transform transition-all duration-300 ease-in-out
          ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}
          lg:relative lg:translate-x-0
          z-50 shadow-2xl backdrop-blur-lg
          border-l border-white/10
          flex flex-col
        `}
      >
        {/* Logo/Header Area */}
        <div className="flex items-center justify-between p-6 border-b border-white/10">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-white/10 rounded-lg">
              <LayoutDashboard className="h-6 w-6 text-blue-200" />
            </div>
            <h2 className="text-xl font-bold bg-gradient-to-r from-white to-blue-200 bg-clip-text text-transparent">
              מערכת ניהול
            </h2>
          </div>
          <button 
            onClick={toggleSidebar} 
            className="lg:hidden text-white hover:text-blue-200 transition-colors"
          >
            <X className="h-6 w-6 hover:rotate-90 transition-transform duration-300" />
          </button>
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto overflow-x-hidden">
          <div className="mt-6 px-3 space-y-1">
            {navItems.map((item) => (
              <NavItem key={item.path || item.text} {...item} />
            ))}
          </div>
        </nav>
      </aside>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Enhanced Top Bar */}
        <header className="bg-white shadow-sm z-40 border-b border-gray-200">
          <div className="flex items-center justify-between px-6 py-4">
            <div className="flex items-center">
              <button 
                onClick={toggleSidebar} 
                className="text-gray-500 lg:hidden hover:text-blue-600 transition-all duration-300 hover:rotate-180"
              >
                <Menu className="h-6 w-6" />
              </button>
              <h1 className="text-2xl font-bold text-gray-800 hidden lg:block mr-4">
                {navItems.find(item => item.path === location.pathname)?.text || 'דשברד'}
              </h1>
            </div>

            {/* User Actions */}
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-600 ml-4 hidden lg:block">
                👋 שלום, {user.username}
              </span>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => navigate('/dashboard/profile')}
                  className="bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded-lg text-sm transition-all duration-300 flex items-center space-x-2 hover:shadow-md"
                >
                  <User className="h-4 w-4 ml-2" />
                  <span>פרופיל</span>
                </button>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg text-sm transition-all duration-300 flex items-center space-x-2 hover:shadow-md"
                >
                  <LogOut className="h-4 w-4 ml-2" />
                  <span>התנתקות</span>
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50 p-6">
          <div className="max-w-7xl mx-auto">
            <Routes>
              <Route index element={<DashboardContent />} />
              <Route path="profile" element={<UserProfile user={user} />} />
              <Route path="end-of-day" element={<EndOfDayForm user={user} />} />
              <Route path="reports" element={<ReportsList user={user} />} />
              <Route path="tasks" element={<Tasks user={user} />} />
              <Route path="future-visits" element={<FutureVisits user={user} />} />
              <Route path="employee-requests" element={<EmployeeRequests user={user} />} />
              <Route path="ai-analyzer" element={<AIAnalyzerContent />} />
              {user.role === 'admin' && (
                <>
                  <Route path="admin/users" element={<UserManagement />} />
                  <Route path="admin/reports-management" element={<ReportsManagement />} />
                  <Route path="admin/surveys" element={<SurveyManagement />} />
                  <Route path="admin/surveys/:surveyId/results" element={<SurveyResults />} />
                  <Route path="admin/salary-surveys" element={<SalaryUpgradeSurvey />} />
                  <Route path="admin/branches" element={<BranchManagement />} />
                </>
              )}
            </Routes>
            {/* Enhanced Developer Info */}
            <div className="text-center text-gray-500 mt-8 py-4 border-t border-gray-200">
              <p className="text-sm">פותח ע"י ישי אלמולי © {new Date().getFullYear()}</p>
            </div>
          </div>
        </main>
      </div>

      {/* Enhanced Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black/30 backdrop-blur-sm z-40 lg:hidden transition-opacity duration-300"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default ModernDashboardLayout;