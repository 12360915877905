import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import MonthlyGoalForm from './MonthlyGoalForm';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Loader, AlertCircle } from 'lucide-react';
import { motion } from 'framer-motion';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlyGoalsDashboard = ({ userRole }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [branches, setBranches] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const hebrewMonths = [
    'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
    'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
  ];

  const hebrewCategories = {
    'cashierBags': 'שקיות קופה',
    'pallets': 'משטחים',
    'supplierPallets': 'משטחי ספקים',
    'orderAndCleanliness': 'סדר וניקיון',
    'unsignedDocuments': 'מסמכים לא חתומים',
    'redStamps': 'חותמות אדומות'
  };

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/api/monthly-goals`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { year: selectedYear, month: selectedMonth, branch: selectedBranch || null }
      });
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('שגיאה בטעינת נתוני הדשבורד. אנא נסה שוב מאוחר יותר.');
      setLoading(false);
    }
  }, [selectedYear, selectedMonth, selectedBranch]);

  useEffect(() => {
    fetchBranches();
    fetchDashboardData();
  }, [fetchDashboardData]);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      setError('שגיאה בטעינת רשימת הסניפים. אנא נסה שוב מאוחר יותר.');
    }
  };

  const isGoalMet = (category, values) => {
    switch (category) {
      case 'cashierBags':
        return values.actual <= values.goal;
      case 'unsignedDocuments':
        return values.actual === 0;
      default:
        return values.actual >= values.goal;
    }
  };

  const renderChart = (data, title, category) => {
    if (!data || typeof data !== 'object' || !('goal' in data) || !('actual' in data)) {
      return <p className="text-gray-500 text-center">אין נתונים זמינים</p>;
    }

    const chartData = {
      labels: ['יעד', 'בפועל'],
      datasets: [{
        label: title,
        data: [data.goal || 0, data.actual || 0],
        backgroundColor: ['rgba(59, 130, 246, 0.6)', 'rgba(16, 185, 129, 0.6)'],
        borderColor: ['rgba(59, 130, 246, 1)', 'rgba(16, 185, 129, 1)'],
        borderWidth: 1,
      }],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleFont: { size: 16 },
          bodyFont: { size: 14 },
          padding: 12,
        },
      },
      scales: {
        y: { 
          beginAtZero: true, 
          grid: { display: false },
          ticks: {
            callback: function(value) {
              if (category === 'cashierBags') {
                return value + ' שקיות';
              }
              return value;
            }
          }
        },
        x: { grid: { display: false } },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  const handleGoalUpdated = () => {
    fetchDashboardData();
    setShowForm(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8 sm:py-12"
      dir="rtl"
    >
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8 sm:mb-12 text-center">לוח מחוונים יעדים חודשיים</h1>
      
      <motion.div 
        className="bg-white rounded-2xl shadow-lg p-6 sm:p-8 mb-8 sm:mb-12"
        whileHover={{ boxShadow: "0 10px 30px rgba(0,0,0,0.1)" }}
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
          <div className="flex flex-wrap gap-4">
            <select
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(Number(e.target.value));
                fetchDashboardData();
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full sm:w-auto"
            >
              {[...Array(5)].map((_, i) => {
                const year = new Date().getFullYear() - 2 + i;
                return <option key={year} value={year}>{year}</option>;
              })}
            </select>
            <select
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(Number(e.target.value));
                fetchDashboardData();
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full sm:w-auto"
            >
              {hebrewMonths.map((month, index) => (
                <option key={index + 1} value={index + 1}>{month}</option>
              ))}
            </select>
            <select
              value={selectedBranch}
              onChange={(e) => {
                setSelectedBranch(e.target.value);
                fetchDashboardData();
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full sm:w-auto"
            >
              <option value="">כל הסניפים</option>
              {branches.map((branch) => (
                <option key={branch._id} value={branch.name}>{branch.name}</option>
              ))}
            </select>
          </div>
          {userRole === 'admin' && (
            <motion.button
              onClick={() => setShowForm(!showForm)}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {showForm ? 'הסתר טופס' : 'הוסף/עדכן יעדים'}
            </motion.button>
          )}
        </div>
      </motion.div>

      {userRole === 'admin' && showForm && (
        <div className="bg-white rounded-xl shadow-lg p-6 mb-6 sm:mb-8">
          <MonthlyGoalForm 
            onGoalUpdated={handleGoalUpdated}
            initialData={dashboardData}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedBranch={selectedBranch}
          />
        </div>
      )}

      <h2 className="text-2xl sm:text-3xl font-bold mb-8 text-center text-gray-800">
        {hebrewMonths[selectedMonth - 1]} {selectedYear}
      </h2>

      {dashboardData && dashboardData.length > 0 ? (
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, staggerChildren: 0.1 }}
        >
          {Object.entries(dashboardData[0]).map(([category, values]) => {
            if (['_id', 'year', 'month', 'branch', '__v', 'createdAt', 'updatedAt'].includes(category)) return null;
            const hebrewCategory = hebrewCategories[category] || category;
            const goalMet = isGoalMet(category, values);
            return (
              <motion.div 
                key={category} 
                className={`bg-white rounded-2xl shadow-lg overflow-hidden transition duration-300 ease-in-out ${goalMet ? 'ring-4 ring-green-500' : ''}`}
                whileHover={{ y: -5, boxShadow: "0 10px 30px rgba(0,0,0,0.1)" }}
              >
                <div className="p-6">
                  <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-4">{hebrewCategory}</h3>
                  {renderChart(values, hebrewCategory, category)}
                  <div className="mt-6 flex justify-between items-center">
                    <div>
                      <p className="text-sm text-gray-500">יעד</p>
                      <p className="text-xl sm:text-2xl font-bold text-gray-900">
                        {category === 'cashierBags' ? `${values.goal} ` : values.goal}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">בפועל</p>
                      <p className="text-xl sm:text-2xl font-bold text-gray-900">
                        {category === 'cashierBags' ? `${values.actual} ` : values.actual}
                      </p>
                    </div>
                  </div>
                  {goalMet ? (
                    <p className="mt-4 text-green-600 font-bold text-center bg-green-100 py-2 rounded-lg">
                      {category === 'unsignedDocuments' ? 'מצוין! אין מסמכים לא חתומים' : 'כל הכבוד! היעד הושג'}
                    </p>
                  ) : (
                    <p className="mt-4 text-red-600 font-bold text-center bg-red-100 py-2 rounded-lg">
                      {category === 'unsignedDocuments' ? 'שים לב! יש מסמכים לא חתומים' : 'היעד לא הושג'}
                    </p>
                  )}
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      ) : (
        <p className="text-center text-xl text-gray-600">אין נתונים זמינים להצגה</p>
      )}
    </motion.div>
  );
};

export default MonthlyGoalsDashboard;