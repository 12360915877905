import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') || null,
  loginTimestamp: localStorage.getItem('loginTimestamp') || null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      state.loginTimestamp = Date.now();
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      localStorage.setItem('loginTimestamp', Date.now());
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loginTimestamp = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('loginTimestamp');
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectLoginTimestamp = (state) => state.auth.loginTimestamp;

export default authSlice.reducer;