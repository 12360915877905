import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, DollarSign, Clock, TrendingUp, Users } from 'lucide-react';
import api from '../../utils/api';
import { toast } from 'react-hot-toast';

const SalaryUpgradeResults = () => {
  const { surveyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [responses, setResponses] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState(null);

  useEffect(() => {
    fetchSurveyAndResponses();
  }, [surveyId]);

  const fetchSurveyAndResponses = async () => {
    try {
      setLoading(true);
      const [surveyRes, responsesRes] = await Promise.all([
        api.get(`/api/salary-surveys/${surveyId}`),
        api.get(`/api/salary-surveys/${surveyId}/responses`)
      ]);

      setSurvey(surveyRes.data);
      setResponses(responsesRes.data);

      // Calculate analytics
      const analytics = calculateAnalytics(responsesRes.data);
      setAnalytics(analytics);
    } catch (error) {
      console.error('Error fetching survey data:', error);
      toast.error('שגיאה בטעינת הנתונים');
    } finally {
      setLoading(false);
    }
  };

  const calculateAnalytics = (responses) => {
    if (!responses.length) return null;

    const totalResponses = responses.length;
    let totalCurrentSalary = 0;
    let totalYearsInCompany = 0;

    responses.forEach(response => {
      totalCurrentSalary += Number(response.currentSalary);
      totalYearsInCompany += Number(response.yearsInCompany);
    });

    return {
      averageCurrentSalary: totalCurrentSalary / totalResponses,
      averageYearsInCompany: totalYearsInCompany / totalResponses,
      totalResponses
    };
  };

  const showDetails = (response) => {
    setSelectedResponse(response);
  };

  const handleExportCsv = async () => {
    try {
      const response = await api.get(`/api/salary-surveys/${surveyId}/export`, {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary-survey-${surveyId}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting CSV:', error);
      toast.error('שגיאה בייצוא הקובץ');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">תוצאות סקר העלאות שכר</h1>
            <button
              onClick={handleExportCsv}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              ייצוא ל-CSV
            </button>
          </div>
          
          {/* Analytics Cards */}
          {analytics && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-blue-600 font-medium">משכורת ממוצעת נוכחית</p>
                    <p className="text-2xl font-bold">₪{analytics.averageCurrentSalary.toFixed(2)}</p>
                  </div>
                  <DollarSign className="h-8 w-8 text-blue-500" />
                </div>
              </div>

              <div className="bg-purple-50 p-4 rounded-lg">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-purple-600 font-medium">ותק ממוצע</p>
                    <p className="text-2xl font-bold">{analytics.averageYearsInCompany.toFixed(1)} שנים</p>
                  </div>
                  <Clock className="h-8 w-8 text-purple-500" />
                </div>
              </div>

              <div className="bg-orange-50 p-4 rounded-lg">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-orange-600 font-medium">מספר בקשות</p>
                    <p className="text-2xl font-bold">{analytics.totalResponses}</p>
                  </div>
                  <Users className="h-8 w-8 text-orange-500" />
                </div>
              </div>
            </div>
          )}

          {/* Detailed Responses Table */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">פירוט הבקשות ({responses.length})</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      תאריך הגשה
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      שם העובד
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      מספר עובד
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      משכורת נוכחית
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ותק בחברה
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      פעולות
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      תפקיד
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      סוג שכר
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responses.map((response, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(response.createdAt).toLocaleDateString('he-IL')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                        {response.employeeName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {response.employeeNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ₪{Number(response.currentSalary).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {response.yearsInCompany} שנים
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => showDetails(response)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          פרטים נוספים
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {response.role}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {response.salaryType === 'hourly' ? 'שעתי' : 'גלובלי'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Add a modal for showing additional details */}
      {selectedResponse && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
            <h3 className="text-lg font-bold mb-4">פרטי בקשה נוספים</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-medium">נימוק לבקשת העלאה</h4>
                <p className="text-gray-600">{selectedResponse.justification}</p>
              </div>
              {selectedResponse.achievements && (
                <div>
                  <h4 className="font-medium">הישגים בולטים</h4>
                  <p className="text-gray-600">{selectedResponse.achievements}</p>
                </div>
              )}
            </div>
            <button
              onClick={() => setSelectedResponse(null)}
              className="mt-6 bg-gray-100 px-4 py-2 rounded hover:bg-gray-200"
            >
              סגור
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalaryUpgradeResults; 