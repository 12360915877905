import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell, RadarChart, PolarGrid, PolarRadiusAxis, Radar, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

export const useReportData = (user) => {
  const [reports, setReports] = useState([]);
  const [graphData, setGraphData] = useState({
    storageArrangement: [],
    checkLevel: [],
    warehousemanStation: [],
    returnsCorner: [],
    combinedScore: []
  });
  const [abnormalQuantities, setAbnormalQuantities] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState([]);
  const [abnormalItemsDetailed, setAbnormalItemsDetailed] = useState([]);
  const [allBranches, setAllBranches] = useState([]);

  const processGraphData = useCallback((reportData) => {
    const branchScores = {};
    const branchAbnormalQuantities = {};
    const dailyScores = {};

    reportData.forEach(report => {
      const reportDate = new Date(report.date).toISOString().split('T')[0];
      if (!branchScores[report.branch]) {
        branchScores[report.branch] = {
          storageArrangement: [],
          checkLevel: [],
          warehousemanStation: [],
          returnsCorner: [],
        };
        branchAbnormalQuantities[report.branch] = {
          abnormalGood: 0,
          abnormalDamaged: 0,
        };
      }
      if (!dailyScores[reportDate]) {
        dailyScores[reportDate] = {
          storageArrangement: [],
          checkLevel: [],
          warehousemanStation: [],
          returnsCorner: [],
        };
      }

      branchScores[report.branch].storageArrangement.push(report.storageArrangementScore);
      branchScores[report.branch].checkLevel.push(report.checkLevelScore);
      branchScores[report.branch].warehousemanStation.push(report.warehousemanStationScore);
      branchScores[report.branch].returnsCorner.push(report.returnsCornerScore);

      dailyScores[reportDate].storageArrangement.push(report.storageArrangementScore);
      dailyScores[reportDate].checkLevel.push(report.checkLevelScore);
      dailyScores[reportDate].warehousemanStation.push(report.warehousemanStationScore);
      dailyScores[reportDate].returnsCorner.push(report.returnsCornerScore);

      if (Array.isArray(report.abnormalGoodsList)) {
        branchAbnormalQuantities[report.branch].abnormalGood += report.abnormalGoodsList.reduce((sum, item) => sum + Number(item.quantity), 0);
      }
      if (Array.isArray(report.damagedGoodsList)) {
        branchAbnormalQuantities[report.branch].abnormalDamaged += report.damagedGoodsList.reduce((sum, item) => sum + Number(item.quantity), 0);
      }
    });

    const average = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

    const processedData = Object.keys(branchScores).map(branch => {
      const storageArrangement = average(branchScores[branch].storageArrangement);
      const checkLevel = average(branchScores[branch].checkLevel);
      const warehousemanStation = average(branchScores[branch].warehousemanStation);
      const returnsCorner = average(branchScores[branch].returnsCorner);
      const combinedScore = (storageArrangement + checkLevel + warehousemanStation + returnsCorner) / 4;

      return {
        name: branch,
        storageArrangement,
        checkLevel,
        warehousemanStation,
        returnsCorner,
        combinedScore,
        abnormalGood: branchAbnormalQuantities[branch].abnormalGood,
        abnormalDamaged: branchAbnormalQuantities[branch].abnormalDamaged
      };
    });

    const abnormalQuantitiesData = Object.keys(branchAbnormalQuantities).map(branch => ({
      name: branch,
      abnormalGood: branchAbnormalQuantities[branch].abnormalGood,
      abnormalDamaged: branchAbnormalQuantities[branch].abnormalDamaged,
    }));

    const trendData = Object.keys(dailyScores).map(date => ({
      date,
      storageArrangement: average(dailyScores[date].storageArrangement),
      checkLevel: average(dailyScores[date].checkLevel),
      warehousemanStation: average(dailyScores[date].warehousemanStation),
      returnsCorner: average(dailyScores[date].returnsCorner),
    })).sort((a, b) => new Date(a.date) - new Date(b.date));

    setGraphData({
      storageArrangement: processedData,
      checkLevel: processedData,
      warehousemanStation: processedData,
      returnsCorner: processedData,
      combinedScore: processedData
    });
    setAbnormalQuantities(abnormalQuantitiesData);
    setTrendData(trendData);
    setBranches([...new Set(reportData.map(report => report.branch))]);

    // Process detailed abnormal items data
    const detailedItems = [];
    reportData.forEach(report => {
      const processItems = (items, type) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            detailedItems.push({
              branch: report.branch,
              date: new Date(report.date).toLocaleDateString('he-IL'),
              type: type,
              product: item.product,
              barcode: item.barcode,
              quantity: Number(item.quantity)
            });
          });
        }
      };

      processItems(report.abnormalGoodsList, 'תקין חריג');
      processItems(report.damagedGoodsList, 'פגום חריג');
    });

    setAbnormalItemsDetailed(detailedItems);
  }, []);

  const fetchReports = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const endpoint = user.role === 'admin' ? '/api/reports/all' : '/api/reports/user';
      const response = await axios.get(`${API_URL}${endpoint}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setReports(response.data);
      processGraphData(response.data);
    } catch (error) {
      console.error('שגיאה בטעינת הדוחות:', error);
      setError('שגיאה בטעינת הדוחות. אנא נסה שוב.');
    } finally {
      setIsLoading(false);
    }
  }, [user.role, processGraphData]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  useEffect(() => {
    if (reports.length > 0) {
      const uniqueBranches = [...new Set(reports.map(report => report.branch))];
      setAllBranches(uniqueBranches);
    }
  }, [reports]);

  return {
    reports,
    graphData,
    abnormalQuantities,
    trendData,
    isLoading,
    error,
    branches: allBranches,
    fetchReports,
    processGraphData,
    abnormalItemsDetailed
  };
};

export const useReportFilters = (reports, processGraphData) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [originalReports, setOriginalReports] = useState(reports);

  // Keep track of original reports
  useEffect(() => {
    setOriginalReports(reports);
  }, [reports]);

  const filterReports = useCallback(() => {
    // Always start with original reports
    const filtered = originalReports.filter(report => {
      const reportDate = new Date(report.date);
      const dateFilter = (!startDate || reportDate >= new Date(startDate)) &&
                        (!endDate || reportDate <= new Date(endDate));
      
      // Only apply branch filter if a specific branch is selected
      if (selectedBranch && selectedBranch !== "") {
        return dateFilter && report.branch === selectedBranch;
      }
      
      // If no branch is selected or "כל הסניפים" is selected, show all branches
      return dateFilter;
    });

    processGraphData(filtered);
  }, [originalReports, startDate, endDate, selectedBranch, processGraphData]);

  // Run filterReports whenever filters change
  useEffect(() => {
    filterReports();
  }, [startDate, endDate, selectedBranch, filterReports]);

  return {
    startDate,
    endDate,
    selectedBranch,
    setStartDate,
    setEndDate,
    setSelectedBranch,
    filterReports
  };
};

export const useExportToCSV = (reports, startDate, endDate) => {
  return useCallback(() => {
    const filteredData = reports.filter(report => {
      const reportDate = new Date(report.date);
      return (!startDate || reportDate >= new Date(startDate)) &&
             (!endDate || reportDate <= new Date(endDate));
    });
  
    const headers = [
      'תאריך',
      'סניף',
      'ציון סידור מחסן',
      'ציון רמ בדיקה',
      'ציון עמדת מחסנאי',
      'ציון פינת חזרות',
      'עבודה עם נהלים',
      'סחורה תקינה חריגה',
      'סחורה תקינה חריגה - פירוט',
      'סחורה פגומה חריגה',
      'סחורה פגומה חריגה - פירוט',
      'עודף בכח אדם',
      'חוסר בכח אדם',
      'צרכי הדרכה',
      'פירוט עבודת מחסנאי',
      'הצעות לשיפור',
      'תקינות ציוד המחסן'
    ];
  
    const escapeCSV = (value) => {
      if (value == null) return '';
      return `"${String(value).replace(/"/g, '""')}"`;
    };
  
    const csvContent = [
      headers.join(','),
      ...filteredData.map(report => {
        const abnormalGoodsDetails = report.abnormalGoodsList.map(item => 
          `${item.product} (${item.barcode}): ${item.quantity}`
        ).join('; ');

        const damagedGoodsDetails = report.damagedGoodsList.map(item => 
          `${item.product} (${item.barcode}): ${item.quantity}`
        ).join('; ');

        return [
          new Date(report.date).toLocaleDateString('he-IL'),
          report.branch,
          report.storageArrangementScore,
          report.checkLevelScore,
          report.warehousemanStationScore,
          report.returnsCornerScore,
          report.workWithProcedures,
          report.abnormalGoodsStatus,
          abnormalGoodsDetails,
          report.damagedGoodsStatus,
          damagedGoodsDetails,
          report.excessManpower,
          report.lackOfManpower,
          report.trainingNeeds,
          report.warehousemanWorkDetails,
          report.improvementSuggestions,
          report.equipmentStatus
        ].map(escapeCSV).join(',');
      })
    ].join('\n');
  
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
    
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, 'warehouse_reports.csv');
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'warehouse_reports.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }, [reports, startDate, endDate]);
};

export const useChartRenderers = () => {
  const renderBarChart = (data, dataKey, color, title) => (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">{title}</h2>
      <div className="h-64 md:h-80">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 10 }}
              interval={0}
              angle={-45}
              textAnchor="end"
              height={60}
              tickFormatter={(value) => value.length > 10 ? `${value.substr(0, 10)}...` : value}
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip
              formatter={(value, name, props) => [value.toFixed(2), name]}
              labelFormatter={(label) => `סניף: ${label}`}
            />
            <Bar dataKey={dataKey} fill={color} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderLineChart = (data, title) => (
    <div className="bg-white p-4 rounded-lg shadow-lg mb-6">
      <h3 className="text-lg font-bold text-gray-800 mb-4">{title}</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} />
          <YAxis domain={[0, 10]} tick={{ fontSize: 12 }} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="storageArrangement" name="סידור מחסן" stroke="#0088FE" />
          <Line type="monotone" dataKey="checkLevel" name="רמת בדיקה" stroke="#00C49F" />
          <Line type="monotone" dataKey="warehousemanStation" name="עמדת מחסנאי" stroke="#FFBB28" />
          <Line type="monotone" dataKey="returnsCorner" name="פינת חזרות" stroke="#FF8042" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  const renderPieChart = (data, isMobile) => (
    <ResponsiveContainer width="100%" height={300} minHeight={200}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius="80%"
          fill="#8884d8"
          dataKey="value"
          label={({ name, percent }) => isMobile ? `${(percent * 100).toFixed(0)}%` : `${name} ${(percent * 100).toFixed(0)}%`}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        {!isMobile && <Legend />}
      </PieChart>
    </ResponsiveContainer>
  );

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600">{title}</p>
          <p className="text-2xl font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-3 rounded-full bg-${color}-100`}>
          <Icon className={`h-6 w-6 text-${color}-600`} />
        </div>
      </div>
    </div>
  );

  return {
    renderBarChart,
    renderLineChart,
    renderPieChart,
    StatCard
  };
};

export const renderPerformanceComparison = (data, isMobile) => (
  <div className="bg-white p-4 rounded-lg shadow-lg mb-6">
    <h3 className="text-lg font-bold text-gray-800 mb-4">השוואת ביצועים בין קטגוריות</h3>
    <ResponsiveContainer width="100%" height={isMobile ? 200 : 400}>
      {isMobile ? (
        <BarChart layout="vertical" data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" domain={[0, 10]} />
          <YAxis dataKey="name" type="category" width={150} />
          <Tooltip />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      ) : (
        <RadarChart outerRadius={150} data={data}>
          <PolarGrid />
          <PolarRadiusAxis angle={30} domain={[0, 10]} />
          <Radar name="ממוצע ציונים" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          <Legend />
        </RadarChart>
      )}
    </ResponsiveContainer>
  </div>
);

