import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { CheckCircle, Clock, Plus, X, Info } from 'lucide-react';

const EmployeeRequests = () => {
  const [requests, setRequests] = useState([]);
  const [branches, setBranches] = useState([]);
  const [newRequest, setNewRequest] = useState({
    employeeName: '',
    branch: '',
    requestType: '',
    description: '',
    targetDate: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const requestTypeMapping = {
    'salary_increase': 'העלאת שכר',
    'warehouse_help': 'עזרה במחסן',
    'product_integrity': 'תקינות מוצרים',
    'staff_shortage': 'חוסר בעובדים',
    'vacation_days': 'ימי חופש',
    'other': 'אחר'
  };

  useEffect(() => {
    fetchRequests();
    fetchBranches();
  }, []);

  const fetchRequests = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/employee-requests`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setRequests(response.data);
      setError(''); // Clear any previous errors
    } catch (error) {
      setError('שגיאה בטעינת הבקשות');
      console.error('Error fetching requests:', error);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
      setError(''); // Clear any previous errors
    } catch (error) {
      setError('שגיאה בטעינת רשימת הסניפים');
      console.error('Error fetching branches:', error);
    }
  };

  const handleInputChange = (e) => {
    setNewRequest({ ...newRequest, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        ...newRequest,
        status: 'ממתין לאישור'
      };

      const response = await axios.post(`${API_URL}/api/employee-requests`, requestData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      setIsPopupOpen(false);
      setNewRequest({ employeeName: '', branch: '', requestType: '', description: '', targetDate: '' });
      setSuccess('הבקשה נשלחה בהצלחה');
      setError('');
      fetchRequests();
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'שגיאה ביצירת בקשה חדשה';
      setError(errorMessage);
      console.error('Error creating new request:', error);
    }
  };

  const updateRequestStatus = async (requestId, newStatus) => {
    try {
      await axios.put(`${API_URL}/api/employee-requests/${requestId}`, { status: newStatus }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setError(''); // Clear any previous errors
      fetchRequests();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError('אין לך הרשאה לעדכן את סטטוס הבקשה. אנא התחבר מחדש או פנה למנהל המערכת.');
      } else {
        setError('שגיאה בעדכון סטטוס הבקשה. אנא נסה שוב מאוחר יותר.');
      }
      console.error('Error updating request status:', error);
    }
  };

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs sm:text-sm font-medium text-gray-600">{title}</p>
          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-2 sm:p-3 rounded-full bg-${color}-100`}>
          <Icon className={`h-4 w-4 sm:h-6 sm:w-6 text-${color}-600`} />
        </div>
      </div>
    </div>
  );

  const pendingRequests = requests.filter(request => request.status === 'ממתין לאישור').length;
  const approvedRequests = requests.filter(request => request.status === 'מאושר').length;
  const inProgressRequests = requests.filter(request => request.status === 'בטיפול').length;

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8" dir="rtl">
      {success && <div className="text-green-500 mb-4 text-sm sm:text-base">{success}</div>}
      {error && <div className="text-red-500 mb-4 text-sm sm:text-base">{error}</div>}
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8">בקשות עובדים</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-6 sm:mb-8">
        <StatCard title="בקשות ממתינות" value={pendingRequests} icon={Clock} color="yellow" />
        <StatCard title="בקשות בטיפול" value={inProgressRequests} icon={CheckCircle} color="blue" />
        <StatCard title="בקשות מאושרות" value={approvedRequests} icon={CheckCircle} color="green" />
      </div>

      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg mb-6 sm:mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <h2 className="text-xl font-semibold mb-2 sm:mb-0">רשימת בקשות</h2>
          <button 
            onClick={() => setIsPopupOpen(true)}
            className="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center text-sm"
          >
            <Plus className="h-4 w-4 mr-1" />
            הוסף בקשה חדשה
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם העובד</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סוג בקשה</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך יעד</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תיאור</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {requests.map((request) => (
                <tr key={request._id}>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{request.employeeName}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{request.branch}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                    {requestTypeMapping[request.requestType] || request.requestType}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <select
                      value={request.status}
                      onChange={(e) => updateRequestStatus(request._id, e.target.value)}
                      className={`px-2 py-1 rounded-full text-xs font-semibold 
                        ${request.status === 'מאושר' ? 'bg-green-100 text-green-800' : 
                          request.status === 'ממתין לאישור' ? 'bg-yellow-100 text-yellow-800' : 
                          request.status === 'בטיפול' ? 'bg-blue-100 text-blue-800' :
                          'bg-red-100 text-red-800'}`}
                    >
                      <option value="ממתין לאישור">ממתין לאישור</option>
                      <option value="מאושר">מאושר</option>
                      <option value="נדחה">נדחה</option>
                      <option value="בטיפול">בטיפול</option>
                    </select>
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">
                    {request.targetDate ? new Date(request.targetDate).toLocaleDateString('he-IL') : 'לא נקבע'}
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => setSelectedRequest(request)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Info className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Request Description Popup */}
      {selectedRequest && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{selectedRequest.requestType}</h3>
              <button onClick={() => setSelectedRequest(null)} className="text-gray-500 hover:text-gray-700">
                <X className="h-5 w-5 sm:h-6 sm:w-6" />
              </button>
            </div>
            <p className="text-sm text-gray-600 mb-2">תיאור הבקשה:</p>
            <p className="text-sm">{selectedRequest.description}</p>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">הוסף בקשה חדשה</h3>
              <button onClick={() => setIsPopupOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X className="h-5 w-5 sm:h-6 sm:w-6" />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <div>
                  <label htmlFor="employeeName" className="block text-sm font-medium text-gray-700 mb-1">שם העובד</label>
                  <input
                    type="text"
                    id="employeeName"
                    name="employeeName"
                    value={newRequest.employeeName}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
                  <select
                    id="branch"
                    name="branch"
                    value={newRequest.branch}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                    required
                  >
                    <option value="">בחר סניף</option>
                    {branches.map(branch => (
                      <option key={branch._id} value={branch.name}>{branch.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="requestType" className="block text-sm font-medium text-gray-700 mb-1">סוג הבקשה</label>
                <select
                  id="requestType"
                  name="requestType"
                  value={newRequest.requestType}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  required
                >
                  <option value="">בחר סוג בקשה</option>
                  <option value="salary_increase">העלאת שכר</option>
                  <option value="warehouse_help">עזרה במחסן</option>
                  <option value="product_integrity">תקינות מוצרים</option>
                  <option value="staff_shortage">חוסר בעובדים</option>
                  <option value="vacation_days">ימי חופש</option>
                  <option value="other">אחר</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">תיאור הבקשה</label>
                <textarea
                  id="description"
                  name="description"
                  value={newRequest.description}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  rows="3"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="targetDate" className="block text-sm font-medium text-gray-700 mb-1">תאריך יעד (אופציונלי)</label>
                <input
                  type="date"
                  id="targetDate"
                  name="targetDate"
                  value={newRequest.targetDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
              </div>
              <div className="flex justify-center">
                <button 
                  type="submit" 
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out shadow-md"
                >
                  <Plus className="h-5 w-5 ml-2" />
                  שלח בקשה
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeRequests;