import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Save, AlertCircle, CheckCircle, Plus, Trash2, Camera } from 'lucide-react';
import { useSelector } from 'react-redux';

const EndOfDayForm = () => {
  const user = useSelector(state => state.auth.user);
  const [formData, setFormData] = useState({
    fullName: '',
    date: '',
    branch: '',
    storageArrangementScore: '',
    checkLevelScore: '',
    warehousemanStationScore: '',
    returnsCornerScore: '',
    workWithProcedures: '',
    abnormalGoodsStatus: '',
    abnormalGoodsList: [],
    damagedGoodsStatus: '',
    damagedGoodsList: [],
    excessManpower: '',
    lackOfManpower: '',
    trainingNeeds: '',
    warehousemanWorkDetails: '',
    improvementSuggestions: '',
    equipmentStatus: '',
    equipmentGaps: '',
    location: { latitude: null, longitude: null },
    photos: []
  });
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    const fetchUserDataAndBranches = async () => {
      try {
        const [userResponse, branchesResponse] = await Promise.all([
          axios.get(`${API_URL}/api/users/profile`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }),
          axios.get(`${API_URL}/api/branches`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          })
        ]);

        setFormData(prevState => ({
          ...prevState,
          fullName: userResponse.data.fullName || ''
        }));

        setBranches(branchesResponse.data);
      } catch (error) {
        console.error('Error fetching user data or branches:', error);
        setError('שגיאה בטעינת נתוני משתמש או סניפים');
      }
    };

    fetchUserDataAndBranches();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prev => ({
            ...prev,
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          }));
        },
        (error) => {
          setLocationError('נא לאפשר גישה למיקום כדי להמשיך');
          console.error('Error getting location:', error);
        }
      );
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleGoodsListChange = (type, index, field, value) => {
    setFormData(prevState => {
      const newList = [...prevState[`${type}GoodsList`]];
      newList[index] = { ...newList[index], [field]: value };
      return { ...prevState, [`${type}GoodsList`]: newList };
    });
  };

  const addGoodsItem = (type) => {
    setFormData(prevState => ({
      ...prevState,
      [`${type}GoodsList`]: [...prevState[`${type}GoodsList`], { product: '', barcode: '', quantity: '' }]
    }));
  };

  const removeGoodsItem = (type, index) => {
    setFormData(prevState => ({
      ...prevState,
      [`${type}GoodsList`]: prevState[`${type}GoodsList`].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_URL}/api/reports`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Report submitted:', response.data);
      setSuccess(true);
      // Reset form fields except fullName
      setFormData(prevState => ({
        ...prevState,
        date: '',
        branch: '',
        storageArrangementScore: '',
        checkLevelScore: '',
        warehousemanStationScore: '',
        returnsCornerScore: '',
        workWithProcedures: '',
        abnormalGoodsStatus: '',
        abnormalGoodsList: [],
        damagedGoodsStatus: '',
        damagedGoodsList: [],
        excessManpower: '',
        lackOfManpower: '',
        trainingNeeds: '',
        warehousemanWorkDetails: '',
        improvementSuggestions: '',
        equipmentStatus: '',
        equipmentGaps: '',
      }));
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'שגיאה בשליחת הטופס';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhotoUpload = async (e) => {
    const files = Array.from(e.target.files);
    
    for (const file of files) {
      try {
        if (!file.type.startsWith('image/')) {
          setError('נא להעלות קבצי תמונה בלבד');
          continue;
        }

        if (file.size > 10 * 1024 * 1024) {
          setError('גודל התמונה חייב להיות קטן מ-10MB');
          continue;
        }

        setIsLoading(true);

        const compressedFile = await compressImage(file);
        const base64 = await convertToBase64(compressedFile);

        const response = await axios.post(`${API_URL}/api/reports/upload`, {
          image: base64
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.data.url) {
          throw new Error('No URL received from server');
        }

        setFormData(prev => ({
          ...prev,
          photos: [...prev.photos, {
            url: response.data.url,
            public_id: response.data.public_id,
            caption: ''
          }]
        }));

        setError(null);
      } catch (error) {
        console.error('Error uploading photo:', error);
        setError(error.response?.data?.message || 'שגיאה בהעלאת תמונה');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePhotoCaption = (index, caption) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.map((photo, i) => 
        i === index ? { ...photo, caption } : photo
      )
    }));
  };

  const removePhoto = (index) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  const renderScoreSelect = (name, label) => (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <select
        id={name}
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
        required
      >
        <option value="">בחר ציון</option>
        {[1,2,3,4,5,6,7,8,9,10].map(score => (
          <option key={score} value={score}>{score}</option>
        ))}
      </select>
    </div>
  );

  const renderRadioGroup = (name, label, options = ['כן', 'לא']) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <div className="flex flex-wrap gap-4">
        {options.map((option) => (
          <label key={option} className="inline-flex items-center">
            <input
              type="radio"
              name={name}
              value={option}
              checked={formData[name] === option}
              onChange={handleInputChange}
              className="form-radio text-blue-600 h-5 w-5"
              required
            />
            <span className="mr-2 text-sm text-gray-700">{option}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const renderGoodsList = (type) => (
    <div className="mb-4">
      <h4 className="text-lg font-semibold mb-2">
        {type === 'abnormal' ? 'פירוט סחורה תקינה חריגה' : 'פירוט סחורה פגומה חריגה'}
      </h4>
      {formData[`${type}GoodsList`].map((item, index) => (
        <div key={index} className="flex flex-wrap -mx-2 mb-2">
          <div className="w-full md:w-1/3 px-2 mb-2">
            <input
              type="text"
              placeholder="מוצר"
              value={item.product}
              onChange={(e) => handleGoodsListChange(type, index, 'product', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full md:w-1/3 px-2 mb-2">
            <input
              type="text"
              placeholder="ברקוד"
              value={item.barcode}
              onChange={(e) => handleGoodsListChange(type, index, 'barcode', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full md:w-1/4 px-2 mb-2">
            <input
              type="number"
              placeholder="כמות"
              value={item.quantity}
              onChange={(e) => handleGoodsListChange(type, index, 'quantity', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full md:w-auto px-2 mb-2 flex items-center">
            <button
              type="button"
              onClick={() => removeGoodsItem(type, index)}
              className="p-2 bg-red-100 text-red-600 rounded-md hover:bg-red-200 transition duration-200"
            >
              <Trash2 size={20} />
            </button>
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={() => addGoodsItem(type)}
        className="mt-2 px-4 py-2 bg-blue-100 text-blue-600 rounded-md hover:bg-blue-200 transition duration-200 flex items-center"
      >
        <Plus size={20} className="ml-2" />
        הוסף פריט
      </button>
    </div>
  );

  const renderBranchSelect = () => (
    <div className="mb-4">
      <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
      <select
        id="branch"
        name="branch"
        value={formData.branch}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        required
      >
        <option value="">בחר סניף</option>
        {branches.map(branch => (
          <option key={branch._id} value={branch.name}>
            {branch.name}
          </option>
        ))}
      </select>
    </div>
  );

  const renderPhotoUpload = () => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-xl font-semibold mb-4">העלאת תמונות</h2>
        
        <div className="flex items-center justify-center w-full">
          <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Camera className="w-10 h-10 mb-3 text-gray-400" />
              <p className="mb-2 text-sm text-gray-500">לחץ להעלאת תמונה</p>
            </div>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              multiple
              onChange={handlePhotoUpload}
              className="hidden"
            />
          </label>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          {formData.photos.map((photo, index) => (
            <div key={index} className="relative">
              <img 
                src={photo.url} 
                alt={`תמונה ${index + 1}`} 
                className="w-full h-40 object-cover rounded-lg"
              />
              <input
                type="text"
                placeholder="הוסף כיתוב לתמונה"
                value={photo.caption}
                onChange={(e) => handlePhotoCaption(index, e.target.value)}
                className="w-full p-2 border rounded-md mt-2"
              />
              <button
                type="button"
                onClick={() => removePhoto(index)}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_WIDTH = 1200;
          const MAX_HEIGHT = 1200;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height * MAX_WIDTH) / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width * MAX_HEIGHT) / height);
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.7;
          if (file.size > 3 * 1024 * 1024) quality = 0.5; // More compression for larger files

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', quality);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="container mx-auto px-4 py-8" dir="rtl">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">הגשת דו"ח יומי</h1>
      
      {error && (
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      )}
      
      {success && (
        <div className="bg-green-100 border-r-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          <div className="flex">
            <CheckCircle className="h-5 w-5 ml-2" />
            <p>הדוח נשלח בהצלחה!</p>
          </div>
        </div>
      )}

      {locationError && (
        <div className="bg-yellow-100 border-r-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{locationError}</p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">פרטים כלליים</h2>
            <div className="mb-4">
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">שם מלא</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                readOnly
              />
            </div>
            <div className="mb-4">
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">תאריך</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            {renderBranchSelect()}
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">ציוני מחסן</h2>
            {renderScoreSelect('storageArrangementScore', 'ציון סידור המחסן')}
            {renderScoreSelect('checkLevelScore', 'ציון רמת בדיקה')}
            {renderScoreSelect('warehousemanStationScore', 'ציון עמדת מחסנאי')}
            {renderScoreSelect('returnsCornerScore', 'ציון פינת חזרות')}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">מצב סחורה</h2>
            {renderRadioGroup('workWithProcedures', 'עבודה עם נהלים')}
            {renderRadioGroup('abnormalGoodsStatus', 'האם יש סחורה תקינה חריגה?')}
            {formData.abnormalGoodsStatus === 'כן' && renderGoodsList('abnormal')}
            {renderRadioGroup('damagedGoodsStatus', 'האם יש סחורה פגומה חריגה?')}
            {formData.damagedGoodsStatus === 'כן' && renderGoodsList('damaged')}
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">כוח אדם וציוד</h2>
            {renderRadioGroup('excessManpower', 'עודף בכח אדם')}
            {renderRadioGroup('lackOfManpower', 'חוסר בכח אדם')}
            {renderRadioGroup('equipmentStatus', 'תקינות ציוד המחסן', ['תקין', 'לא תקין'])}
            {formData.equipmentStatus === 'לא תקין' && (
              <div className="mb-4">
                <label htmlFor="equipmentGaps" className="block text-sm font-medium text-gray-700 mb-1">פירוט פערים בציוד</label>
                <textarea
                  id="equipmentGaps"
                  name="equipmentGaps"
                  value={formData.equipmentGaps}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                ></textarea>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-xl font-semibold mb-4">פרטים נוספים</h2>
          <div className="mb-4">
            <label htmlFor="trainingNeeds" className="block text-sm font-medium text-gray-700 mb-1">צרכי הדרכה</label>
            <textarea
              id="trainingNeeds"
              name="trainingNeeds"
              value={formData.trainingNeeds}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="warehousemanWorkDetails" className="block text-sm font-medium text-gray-700 mb-1">פירוט עבודת מחסנאי</label>
            <textarea
              id="warehousemanWorkDetails"
              name="warehousemanWorkDetails"
              value={formData.warehousemanWorkDetails}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="improvementSuggestions" className="block text-sm font-medium text-gray-700 mb-1">הצעות לשיפור</label>
            <textarea
              id="improvementSuggestions"
              name="improvementSuggestions"
              value={formData.improvementSuggestions}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
        </div>

        {renderPhotoUpload()}

        <div className="flex justify-center">
          <button 
            type="submit" 
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            disabled={isLoading}
          >
            <Save className="inline-block ml-2 h-5 w-5" />
            {isLoading ? 'שולח...' : 'שלח דו"ח'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EndOfDayForm;