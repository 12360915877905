import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, AlertCircle, Loader, ClipboardList, ArrowRight, ArrowLeft } from 'lucide-react';
import api from '../../utils/api';

const PublicSurvey = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchSurvey();
  }, [surveyId]);

  const fetchSurvey = async () => {
    try {
      const response = await api.get(`/api/surveys/${surveyId}`);
      setSurvey(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching survey:', error);
      setError('הסקר לא נמצא או שאינו זמין יותר');
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await api.post(`/api/surveys/${surveyId}/responses`, {
        answers: Object.entries(answers).map(([questionId, answer]) => ({
          questionId,
          answer
        }))
      });
      // Show success message with animation
      setCurrentStep(survey.questions.length);
      setTimeout(() => navigate('/'), 2000);
    } catch (error) {
      console.error('Error submitting survey:', error);
      setError('אירעה שגיאה בשליחת הסקר. אנא נסה שוב.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderNumberInput = (question, index) => {
    const marks = Array.from(
      { length: question.maxValue - question.minValue + 1 }, 
      (_, i) => question.minValue + i
    );

    // Special styling for 1-10 scale
    const is1to10 = question.maxValue === 10;

    if (is1to10) {
      return (
        <div className="mt-6">
          <div className="flex flex-wrap justify-center gap-3">
            {marks.map((mark) => (
              <button
                key={mark}
                type="button"
                className={`
                  relative w-16 h-16 rounded-lg focus:outline-none transition-all duration-200
                  ${answers[index] === mark 
                    ? 'bg-blue-600 text-white shadow-lg scale-110' 
                    : 'bg-gray-50 hover:bg-gray-100 border border-gray-200'}
                  ${mark <= 3 ? 'hover:bg-red-50' : ''}
                  ${mark > 3 && mark <= 7 ? 'hover:bg-yellow-50' : ''}
                  ${mark > 7 ? 'hover:bg-green-50' : ''}
                `}
                onClick={() => setAnswers({ ...answers, [index]: mark })}
              >
                <span className="text-xl font-semibold">{mark}</span>
                {mark === 1 && <span className="absolute -bottom-6 right-0 text-xs text-gray-500">נמוך מאוד</span>}
                {mark === 5 && <span className="absolute -bottom-6 right-0 text-xs text-gray-500">בינוני</span>}
                {mark === 10 && <span className="absolute -bottom-6 right-0 text-xs text-gray-500">גבוה מאוד</span>}
              </button>
            ))}
          </div>
          <div className="mt-10 bg-gray-50 rounded-lg p-4">
            <div className="flex justify-between text-sm text-gray-600">
              <div className="text-center">
                <div className="w-3 h-3 bg-red-200 rounded-full mx-auto mb-1"></div>
                <span>1-3</span>
                <p>תדירות נמוכה</p>
              </div>
              <div className="text-center">
                <div className="w-3 h-3 bg-yellow-200 rounded-full mx-auto mb-1"></div>
                <span>4-7</span>
                <p>תדירות בינונית</p>
              </div>
              <div className="text-center">
                <div className="w-3 h-3 bg-green-200 rounded-full mx-auto mb-1"></div>
                <span>8-10</span>
                <p>תדירות גבוהה</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Original 1-5 scale rendering
    return (
      <div className="mt-4">
        <div className="flex justify-between mb-2">
          {marks.map((mark) => (
            <button
              key={mark}
              type="button"
              className={`
                w-14 h-14 rounded-full focus:outline-none transition-all duration-200 
                ${answers[index] === mark 
                  ? 'bg-blue-600 text-white shadow-lg scale-110' 
                  : 'bg-gray-50 hover:bg-gray-100 border border-gray-200'}
              `}
              onClick={() => setAnswers({ ...answers, [index]: mark })}
            >
              <span className="text-lg font-semibold">{mark}</span>
            </button>
          ))}
        </div>
        <div className="flex justify-between text-sm text-gray-500 px-1 mt-4">
          <span>נמוך</span>
          <span>גבוה</span>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center text-red-600">
          <AlertCircle className="w-12 h-12 mx-auto mb-4" />
          <p className="text-lg">{error}</p>
        </div>
      </div>
    );
  }

  if (!survey) return null;

  const currentQuestion = survey.questions[currentStep];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-50">
      {/* Main content */}
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto"
        >
          {currentStep < survey.questions.length ? (
            <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
              {/* Survey Header */}
              <div className="mb-8 text-center">
                <div className="inline-block p-3 bg-blue-100 rounded-2xl mb-4">
                  <ClipboardList className="w-8 h-8 text-blue-600" />
                </div>
                <h1 className="text-3xl font-bold mb-2 bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
                  {survey.title}
                </h1>
              </div>

              {/* Anonymity notice - Enhanced */}
              <div className="mb-8 p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl border border-blue-100">
                <p className="text-blue-800 text-sm text-center flex items-center justify-center gap-2">
                  <span className="bg-blue-100 p-1 rounded-full">🔒</span>
                  זהו סקר אנונימי - התשובות שלך יישמרו בצורה חסויה
                </p>
              </div>

              {/* Progress Bar - Enhanced */}
              <div className="mb-8">
                <div className="flex justify-between mb-2">
                  <span className="text-sm font-medium text-gray-600">
                    שאלה {currentStep + 1} מתוך {survey.questions.length}
                  </span>
                  <span className="text-sm font-medium text-blue-600">
                    {Math.round((currentStep / survey.questions.length) * 100)}%
                  </span>
                </div>
                <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <motion.div 
                    initial={{ width: 0 }}
                    animate={{ 
                      width: `${(currentStep / survey.questions.length) * 100}%` 
                    }}
                    className="h-full bg-gradient-to-r from-blue-500 to-blue-600 rounded-full"
                    transition={{ duration: 0.5 }}
                  />
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-8">
                <motion.div
                  key={currentStep}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="space-y-6"
                >
                  <h2 className="text-xl font-medium text-gray-900">
                    {currentQuestion.text}
                  </h2>

                  {/* Number Input - Enhanced */}
                  {currentQuestion.type === 'number' ? (
                    <div className="mt-6">
                      <div className="flex flex-wrap justify-center gap-3">
                        {/* ... existing number buttons with enhanced styling ... */}
                        {Array.from(
                          { length: currentQuestion.maxValue - currentQuestion.minValue + 1 },
                          (_, i) => currentQuestion.minValue + i
                        ).map((mark) => (
                          <button
                            key={mark}
                            type="button"
                            className={`
                              relative w-16 h-16 rounded-xl focus:outline-none transition-all duration-200
                              ${answers[currentStep] === mark 
                                ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg scale-110' 
                                : 'bg-gray-50 hover:bg-gray-100 border border-gray-200 hover:border-blue-200'}
                              ${currentQuestion.maxValue === 10 ? `
                                ${mark <= 3 ? 'hover:bg-red-50' : ''}
                                ${mark > 3 && mark <= 7 ? 'hover:bg-yellow-50' : ''}
                                ${mark > 7 ? 'hover:bg-green-50' : ''}
                              ` : ''}
                            `}
                            onClick={() => setAnswers({ ...answers, [currentStep]: mark })}
                          >
                            <span className="text-xl font-semibold">{mark}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    // Text Input - Enhanced
                    <textarea
                      required
                      className="w-full p-4 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none transition-all duration-200 hover:border-blue-200"
                      rows="4"
                      placeholder="הקלד את תשובתך כאן..."
                      onChange={(e) => setAnswers({ ...answers, [currentStep]: e.target.value })}
                      value={answers[currentStep] || ''}
                    />
                  )}

                  {/* Navigation Buttons - Enhanced */}
                  <div className="flex justify-between pt-6">
                    {currentStep > 0 && (
                      <button
                        type="button"
                        onClick={() => setCurrentStep(curr => curr - 1)}
                        className="px-6 py-3 text-blue-600 hover:text-blue-700 transition-colors flex items-center gap-2"
                      >
                        <ArrowRight className="w-4 h-4" />
                        חזור
                      </button>
                    )}
                    
                    {currentStep < survey.questions.length - 1 ? (
                      <button
                        type="button"
                        onClick={() => setCurrentStep(curr => curr + 1)}
                        disabled={!answers[currentStep]}
                        className="bg-gradient-to-r from-blue-600 to-blue-700 text-white px-8 py-3 rounded-xl hover:from-blue-700 hover:to-blue-800 transition-all transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 flex items-center gap-2 shadow-lg"
                      >
                        הבא
                        <ArrowLeft className="w-4 h-4" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={isSubmitting || !answers[currentStep]}
                        className="bg-gradient-to-r from-green-600 to-green-700 text-white px-8 py-3 rounded-xl hover:from-green-700 hover:to-green-800 transition-all transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 flex items-center gap-2 shadow-lg"
                      >
                        {isSubmitting ? (
                          <>
                            <Loader className="animate-spin h-5 w-5" />
                            שולח...
                          </>
                        ) : (
                          <>
                            סיים סקר
                            <CheckCircle className="w-4 h-4" />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </motion.div>
              </form>
            </div>
          ) : (
            // Success Message - Enhanced
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="bg-white rounded-2xl shadow-xl p-8 text-center border border-gray-100"
            >
              <div className="inline-block p-3 bg-green-100 rounded-2xl mb-4">
                <CheckCircle className="w-12 h-12 text-green-600" />
              </div>
              <h2 className="text-2xl font-bold bg-gradient-to-r from-green-600 to-green-800 bg-clip-text text-transparent mb-4">
                תודה על מילוי הסקר!
              </h2>
              <p className="text-gray-600 mb-6">התשובות שלך נשמרו בהצלחה</p>
            </motion.div>
          )}
        </motion.div>
      </div>

      {/* Footer - Enhanced */}
      <div className="mt-6 text-center text-sm text-gray-500 bg-white/50 backdrop-blur-sm py-3 rounded-xl max-w-3xl mx-auto">
        פותח ע״י ישי אלמולי &copy; {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default PublicSurvey; 