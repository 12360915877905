import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { BarChart2, Award, Calendar, TrendingUp, Eye, Link2, Trash2, User, Plus } from 'lucide-react';

const defaultQuestions = [
  {
    text: 'מה רמת שביעות הרצון שלך ממנהל תחום המחסנים?',
    type: 'number',
    minValue: 1,
    maxValue: 5
  },
  {
    text: 'מה תדירות הביקורים החודשית של מנהל תחום מחסנים?',
    type: 'number',
    minValue: 1,
    maxValue: 10
  },
  {
    text: 'איך היית מדרג את רמת המקצועיות של צוות המחסן?',
    type: 'number',
    minValue: 1,
    maxValue: 5
  },
  {
    text: 'מה לדעתך ניתן לשפר בניהול המחסן?',
    type: 'text'
  }
];

const SurveyManagement = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newSurvey, setNewSurvey] = useState({
    title: '',
    description: '',
    questions: defaultQuestions,
    assignedUsers: []
  });
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [analytics, setAnalytics] = useState({
    topPerformer: null,
    mostVisited: null,
    averageStats: null,
    loading: true
  });
  const navigate = useNavigate();

  const calculateAnalytics = async () => {
    try {
      const response = await api.get('/api/surveys/responses/analytics');
      const data = response.data;
      setAnalytics({
        topPerformer: data.topPerformer,
        mostVisited: data.mostVisited,
        averageStats: data.averageStats,
        loading: false
      });
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setAnalytics(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchSurveys();
    fetchUsers();
    calculateAnalytics();
  }, []);

  const fetchSurveys = async () => {
    try {
      const response = await api.get('/api/surveys');
      setSurveys(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching surveys:', error);
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleCreateSurvey = async () => {
    try {
      await api.post('/api/surveys', {
        ...newSurvey,
        assignedUsers: selectedUsers
      });
      setShowCreateModal(false);
      setSelectedUsers([]);
      fetchSurveys();
    } catch (error) {
      console.error('Error creating survey:', error);
      alert(error.response?.data?.message || 'Error creating survey');
    }
  };

  const copyToClipboard = (surveyId) => {
    const link = generateSurveyLink(surveyId);
    navigator.clipboard.writeText(link);
    alert('הקישור הועתק ללוח!');
  };

  const generateSurveyLink = (surveyId) => {
    return `${window.location.origin}/survey/${surveyId}`;
  };

  const renderAnalyticsDashboard = () => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">סטטיסטיקות כלליות</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-green-500">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Award className="h-8 w-8 text-green-500 ml-3" />
              <h4 className="text-lg font-medium">העובד המצטיין</h4>
            </div>
          </div>
          {analytics.loading ? (
            <div className="animate-pulse h-20 bg-gray-200 rounded"></div>
          ) : analytics.topPerformer ? (
            <div className="text-center">
              <p className="text-2xl font-bold text-gray-800">
                {analytics.topPerformer.name}
              </p>
              <p className="text-sm text-gray-600 mt-2">
                ציון ממוצע: {analytics.topPerformer.averageRating.toFixed(1)}
              </p>
              <p className="text-xs text-gray-500 mt-1">
                מתוך {analytics.topPerformer.responsesCount} משובים
              </p>
            </div>
          ) : (
            <p className="text-center text-gray-500">אין נתונים זמינים</p>
          )}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-blue-500">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Calendar className="h-8 w-8 text-blue-500 ml-3" />
              <h4 className="text-lg font-medium">ביקורים תכופים</h4>
            </div>
          </div>
          {analytics.loading ? (
            <div className="animate-pulse h-20 bg-gray-200 rounded"></div>
          ) : analytics.mostVisited ? (
            <div className="text-center">
              <p className="text-2xl font-bold text-gray-800">
                {analytics.mostVisited.storeName}
              </p>
              <p className="text-sm text-gray-600 mt-2">
                {analytics.mostVisited.visitCount} ביקורים בחודש
              </p>
              <p className="text-xs text-gray-500 mt-1">
                ממוצע ביקורים: {analytics.mostVisited.averageVisitsPerMonth}
              </p>
            </div>
          ) : (
            <p className="text-center text-gray-500">אין נתונים זמינים</p>
          )}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-purple-500">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <TrendingUp className="h-8 w-8 text-purple-500 ml-3" />
              <h4 className="text-lg font-medium">סטטיסטיקה כללית</h4>
            </div>
          </div>
          {analytics.loading ? (
            <div className="animate-pulse h-20 bg-gray-200 rounded"></div>
          ) : analytics.averageStats ? (
            <div className="grid grid-cols-2 gap-4 text-center">
              <div>
                <p className="text-sm text-gray-600">שביעות רצון ממוצעת</p>
                <p className="text-xl font-bold text-gray-800">
                  {analytics.averageStats.satisfaction.toFixed(1)}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">רמת מקצועיות</p>
                <p className="text-xl font-bold text-gray-800">
                  {analytics.averageStats.professionalism.toFixed(1)}
                </p>
              </div>
              <div className="col-span-2">
                <p className="text-sm text-gray-600">סה"כ משובים</p>
                <p className="text-xl font-bold text-gray-800">
                  {analytics.averageStats.totalResponses}
                </p>
              </div>
            </div>
          ) : (
            <p className="text-center text-gray-500">אין נתונים זמינים</p>
          )}
        </div>
      </div>
    </div>
  );

  const renderSurveyCard = (survey) => {
    const assignedUsers = survey.assignedUsers || [];
    const surveyUrl = generateSurveyLink(survey._id);
    
    return (
      <div key={survey._id} className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {survey.title}
            </h3>
            <p className="text-gray-600 mb-4">{survey.description}</p>
            
            {/* Assigned Users Section */}
            <div className="flex items-center text-gray-600 mb-4">
              <User className="h-4 w-4 ml-2" />
              <span className="text-sm">משויך ל: </span>
              <div className="flex flex-wrap gap-2 mr-2">
                {assignedUsers.map(user => (
                  <span 
                    key={user._id}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                  >
                    {user.fullName || user.username}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Survey Link - Updated Version */}
        <div className="mt-4 pt-4 border-t border-gray-200">
          <p className="text-sm text-gray-600">
            <div className="flex items-center justify-between">
              <div className="flex items-center text-gray-600">
                <Link2 className="h-4 w-4 ml-2 text-gray-400" />
                <span>קישור לסקר:</span>
              </div>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(surveyUrl);
                  alert('הקישור הועתק בהצלחה!');
                }}
                className="text-blue-600 hover:text-blue-800 text-sm px-2 py-1 rounded-md hover:bg-blue-50 transition-colors"
              >
                העתק קישור
              </button>
            </div>
            <div className="mt-2 bg-gray-50 p-2 rounded-md">
              <div className="overflow-hidden text-ellipsis whitespace-nowrap text-gray-600 text-sm">
                {surveyUrl}
              </div>
            </div>
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-wrap gap-3 mt-4">
          <button
            onClick={() => navigate(`/dashboard/admin/surveys/${survey._id}/results`)}
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Eye className="h-4 w-4 ml-2" />
            צפה בתוצאות
          </button>

          <button
            onClick={() => {
              navigator.clipboard.writeText(surveyUrl);
              alert('הקישור הועתק בהצלחה!');
            }}
            className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            <Link2 className="h-4 w-4 ml-2" />
            העתק קישור
          </button>

          <button
            onClick={async () => {
              if (window.confirm('האם אתה בטוח שברצונך למחוק סקר זה?')) {
                try {
                  await api.delete(`/api/surveys/${survey._id}`);
                  fetchSurveys();
                } catch (error) {
                  console.error('Error deleting survey:', error);
                  alert(error.response?.data?.message || 'Error deleting survey');
                }
              }
            }}
            className="inline-flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            <Trash2 className="h-4 w-4 ml-2" />
            מחק
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800">ניהול סקרים</h2>
        <button
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          onClick={() => setShowCreateModal(true)}
        >
          <Plus className="h-5 w-5 ml-2" />
          סקר חדש
        </button>
      </div>

      {renderAnalyticsDashboard()}

      {/* Create Survey Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
            <h3 className="text-xl font-bold mb-4">יצירת סקר חדש</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">כותרת הסקר</label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={newSurvey.title}
                  onChange={(e) => setNewSurvey({ ...newSurvey, title: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">תיאור</label>
                <textarea
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={newSurvey.description}
                  onChange={(e) => setNewSurvey({ ...newSurvey, description: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  שייך משתמשים לסקר
                </label>
                <div className="max-h-48 overflow-y-auto border rounded-md p-2">
                  {users.map(user => (
                    <div key={user._id} className="flex items-center p-2 hover:bg-gray-50">
                      <input
                        type="checkbox"
                        id={user._id}
                        checked={selectedUsers.includes(user._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers([...selectedUsers, user._id]);
                          } else {
                            setSelectedUsers(selectedUsers.filter(id => id !== user._id));
                          }
                        }}
                        className="ml-2"
                      />
                      <label htmlFor={user._id} className="cursor-pointer">
                        {user.fullName || user.username}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="bg-gray-200 px-4 py-2 rounded-lg hover:bg-gray-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  ביטול
                </button>
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                  onClick={handleCreateSurvey}
                >
                  צור סקר
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Survey List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {surveys.map(survey => renderSurveyCard(survey))}
      </div>
    </div>
  );
};

export default SurveyManagement; 